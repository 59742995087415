'use client';

import { cloneElement, isValidElement } from 'react';

import { clsx } from 'clsx';
import type React from 'react';
import type { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import { useMediaQuery } from '../../shared/hooks';
import type { ButtonBaseProps } from '../button';
import CounterBadge from '../counter-badge';
import type { IIconWrapperProps } from '../icon-wrapper/icon-wrapper';
import { IconWrapper } from '../index';
import Typography from '../typography';

import styles from './icon-button.module.scss';

export interface IIconButtonProps<E extends ElementType = 'button'>
  extends Omit<ButtonBaseProps<E>, 'appearance' | 'size'>,
    IIconWrapperProps {
  active?: boolean;
  additionalText?: string;
  classNameIconWrapper?: string;
  counter?: number;
  tabIndex?: number;
}

export type TIconButtonProps<E extends ElementType = 'button'> =
  IIconButtonProps<E> &
    Omit<ComponentPropsWithoutRef<E>, keyof ButtonBaseProps<E>>;

/**
 * Renders a customizable icon button component with optional counter and additional text.
 *
 * @template E - The type of the element to render, defaults to 'button'.
 *
 * @param {number} [counter] - An optional number to display in a badge on the button.
 * @param {function} [onClick] - Event handler for button click actions.
 * @param {string} [appearance='primary'] - The visual style or theme of the button, such as
 * 'primary', 'advertisement', etc.
 * @param {string} [size] - The size of the icon within the button, such as
 * 'extra_small', 'small', etc.
 * @param {string} [additionalText] - Optional text to display next to the icon.
 * @param {boolean} [active=false] - If true, applies an "active" style to the button.
 * @param {string} [className] - Additional CSS classes for custom styling of the button container.
 * @param {string} [classNameIconWrapper] - Additional CSS classes for custom styling
 * of the icon wrapper.
 * @param {boolean} [asChild=false] - If true, renders the button as a child element
 * of another component.
 * @param {boolean} [isLoading=false] - If true, shows a loading state, disabling interactions.
 * @param {E} [as='button'] - The HTML element or custom component type to use for the button.
 * @param {ReactNode} [children] - The content to display within the button's icon wrapper.
 * @param {TIconButtonProps<E>} props - Additional properties for the icon button component.
 *
 * @returns {React.ReactElement | null} The rendered icon button component or null
 * if `asChild` is true without valid children.
 */

export const IconButton = <E extends ElementType = 'button'>({
  counter,
  onClick,
  appearance = 'primary',
  size,
  additionalText,
  active = false,
  className,
  classNameIconWrapper,
  asChild,
  isLoading,
  as,
  children,
  ...props
}: TIconButtonProps<E>): React.ReactElement | null => {
  const isMobile = useMediaQuery('mobileL');
  const isTabletS = useMediaQuery('tabletS');

  const isDisabled = props.disabled || isLoading;

  const Component = as || 'button';

  const supportsDisabled =
    ['button', 'input', 'select', 'textarea'].includes(Component as string) &&
    !asChild;

  const combinedProps: React.ComponentPropsWithoutRef<typeof Component> = {
    className: clsx(styles.container, className, {
      [styles.wrapper]: !!additionalText,
      [styles.container_advertisement]: appearance === 'advertisement',
    }),
    ...(supportsDisabled
      ? { disabled: isDisabled }
      : { 'aria-disabled': isDisabled }),
    ...props,
    onClick: (e: React.MouseEvent<HTMLElement>) => {
      if (isDisabled) {
        e.preventDefault();
        e.stopPropagation();

        return;
      }

      if (onClick) {
        onClick?.(e);
      }
    },
  };

  const renderContent = (children: ReactNode) => (
    <>
      <IconWrapper
        appearance={additionalText ? undefined : appearance}
        size={size}
        className={clsx(styles.btn, classNameIconWrapper, {
          [styles?.[appearance]]: !additionalText,
          [styles.btn_with_text]: !!additionalText,
          [styles.disabled]: props?.disabled,
          [styles.open]: active,
        })}
      >
        {children}
      </IconWrapper>
      {!!counter && (
        <div className={styles.counter}>
          <CounterBadge
            counter={counter}
            size={appearance === 'header' && isTabletS ? 'small' : 'medium'}
          />
        </div>
      )}
      {!isMobile && !!additionalText && (
        <Typography
          as='span'
          className={styles.btn_text}
          fontWeight={500}
          textWrap='nowrap'
          variant='system_h4'
        >
          {additionalText}
        </Typography>
      )}
    </>
  );

  if (asChild && isValidElement(children)) {
    return cloneElement(children, {
      ...combinedProps,
      children: children.props.children,
    });
  } else if (asChild) {
    return null;
  }

  return <Component {...combinedProps}>{renderContent(children)}</Component>;
};

export default IconButton;
