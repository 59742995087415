import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';

import styles from './skeleton.module.scss';

interface ISkeleton extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  className?: string;
  isCircular?: boolean;
}

const Skeleton: FC<ISkeleton> = ({
  isCircular = false,
  className,
  ...props
}) => (
  <div
    className={clsx(styles.skeleton, className, {
      [styles.skeleton_circular]: isCircular,
    })}
    {...props}
  />
);

export default Skeleton;
