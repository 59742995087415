'use client';

import { useId } from 'react';

import clsx from 'clsx';
import type { Variants } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

import Typography from '../typography';
import type { ITextarea, TTextareaStyle } from './types';

import IconShape from '../../public/icons/icons16/shape.svg';

import styles from './textarea.module.scss';

const variantsMotion: Variants = {
  animate: {
    height: 'auto',
    marginTop: '8px',
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
    y: 0,
  },
  exit: {
    height: 0,
    marginTop: '0px',
    opacity: 0,
    transition: { duration: 0.2 },
    y: -10,
  },
  initial: {
    height: 0,
    opacity: 0,
    y: -10,
  },
};

const Textarea = ({
  name,
  cols,
  rows,
  placeholder,
  maxLength,
  error,
  label,
  textareaClassName,
  labelClassname,
  ...props
}: ITextarea) => {
  const inputID = useId();

  const textareaStyleObject: TTextareaStyle = { '--rows': rows };

  return (
    <div className={clsx(styles.container)}>
      {label && (
        <label className={clsx(styles.label, labelClassname)} htmlFor={inputID}>
          <Typography fontWeight={600} variant='system_h5'>
            {label}
          </Typography>
        </label>
      )}
      <textarea
        {...props}
        cols={cols}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        rows={rows}
        style={textareaStyleObject}
        className={clsx(
          styles.textarea,
          { [styles.textarea_error]: error },
          textareaClassName
        )}
      />
      <AnimatePresence>
        {error && (
          <motion.div
            animate='animate'
            className={clsx(styles.textarea__errorWrapper)}
            exit='exit'
            initial='initial'
            variants={variantsMotion}
          >
            <IconShape className={styles.errorIcon} />
            <Typography fontWeight={400} variant='system_h5'>
              {error}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Textarea;
