'use client';

import { useCallback, useState } from 'react';

import type {
  ColumnDef,
  CoreRow,
  Header,
  TableOptions,
} from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import { useIntersectionObserver } from '../../../shared/hooks';
import { Typography } from '../../index';
import LoadingTable from './loading-table';

import IconTableSortDesc from '../../../public/icons/icons20/arrow-long-down.svg';
import IconTableSort from '../../../public/icons/icons20/arrow-long-up-down.svg';

import styles from './styled-table.module.scss';

//Принимает дженерик данных таблицы
//в этот компонент нужно прокидывать сами данные и колонки, созданные с помощью createColumnHelper
//пример как создавать есть в /story/table
//из нюансов создания колонок есть пропс meta: {}. Там есть 2 параметра:
// 1) onHoverComponent?: ReactNode; - Это то, что должно показываться по ховеру
// 2) hideInTablet?: boolean; - нужно ли скрывать определенную колонку в таблетке
// 2) hideInTabletS?: boolean; - нужно ли скрывать определенную колонку при tabletS
// 2) hideInMobileL?: boolean; - нужно ли скрывать определенную колонку при mobileL
// 2) hideInMobile?: boolean; - нужно ли скрывать определенную колонку при mobile

//Дока по библе - https://tanstack.com/table/latest
interface IClientTable<T> {
  tableColumns: ColumnDef<T>[];
  countToShow?: number;
  cursor?: boolean;
  emptyContent?: ReactNode;
  intersectionCallback?: () => void;
  isLoading?: boolean;
  loadingRowsCount?: number;
  onRowClick?: (value: CoreRow<T>) => void;
  saveHeaderOnEmpty?: boolean;
  tableClassname?: string;
  tableData?: T[];
  tableProps?: Partial<TableOptions<T>>;
}

/**
 * StyledTable component for displaying a styled table with sortable columns.
 * @template T - Type of data for the table.
 * @param {Object} props - The props object.
 * @param {T[]} props.tableData - The array of data for the table.
 * @param {ColumnDef<T>[]} props.tableColumns - The array of column definitions for the table.
 * @param {(value?: CoreRow<T>) => void} [props.onRowClick] - Optional callback function
 * triggered on row click.
 * @returns {ReactNode} React component.
 */

export const StyledTable = <T extends object>({
  tableData,
  tableColumns,
  onRowClick,
  isLoading,
  loadingRowsCount = 5,
  tableClassname,
  emptyContent,
  saveHeaderOnEmpty = false,
  countToShow,
  intersectionCallback,
  tableProps,
  cursor = false,
}: IClientTable<T>) => {
  const [hoveredRow, setHoveredRow] = useState<string>('');

  const intersectionHandler: IntersectionObserverCallback = useCallback(
    ([entry]) => {
      if (entry.isIntersecting && intersectionCallback) {
        intersectionCallback?.();
      }
    },
    [intersectionCallback]
  );

  const callbackRef = useIntersectionObserver(intersectionHandler);

  const isEmpty = (!tableData || tableData?.length === 0) && !isLoading;

  const table = useReactTable({
    columns: tableColumns,
    data: tableData ?? [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...tableProps,
  });

  const getTh = (header: Header<T, unknown>) =>
    flexRender(header.column.columnDef.header, header.getContext()) ?? '';

  const getTableHeader = () => (
    <thead className={styles.tableHead}>
      {table?.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const th = getTh(header);
            const stringTitle = typeof th === 'string' && th.length ? th : '';
            const hasTh = !stringTitle && !!th;

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                {...{
                  onClick: header.column.getToggleSortingHandler(),
                }}
                style={{ width: header.column.getSize() + 'px' }}
                className={clsx(styles.tableHead, {
                  [styles.canSort]: header.column.getCanSort(),
                  [styles.sorted]: header.column.getIsSorted(),
                  [styles.hideInLaptop]:
                    header.column.columnDef.meta?.hideInLaptop,
                  [styles.hideInTablet]:
                    header.column.columnDef.meta?.hideInTablet,
                  [styles.hideInTabletS]:
                    header.column.columnDef.meta?.hideInTabletS,
                  [styles.hideInMobileL]:
                    header.column.columnDef.meta?.hideInMobileL,
                  [styles.hideInMobile]:
                    header.column.columnDef.meta?.hideInMobile,
                })}
              >
                {header.isPlaceholder ? null : (
                  <span className={styles.thContent}>
                    {stringTitle && (
                      <Typography
                        as='span'
                        fontWeight={400}
                        variant='system_h5'
                      >
                        {stringTitle}
                      </Typography>
                    )}

                    {hasTh && th}

                    {header.column.getCanSort() && (
                      <span
                        className={clsx(styles.sortIcon, {
                          [styles.active]: !!header.column.getIsSorted(),
                        })}
                      >
                        {
                          {
                            asc: (
                              <IconTableSortDesc className={styles.sortAsc} />
                            ),
                            desc: <IconTableSortDesc />,
                            false: <IconTableSort />,
                          }[header.column.getIsSorted() as string]
                        }
                      </span>
                    )}
                  </span>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );

  return (
    <table className={clsx(styles.table, tableClassname)}>
      {isEmpty && emptyContent ? (
        <>
          {saveHeaderOnEmpty && getTableHeader()}
          {emptyContent}
        </>
      ) : (
        <>
          {getTableHeader()}
          {isLoading ? (
            <LoadingTable<T>
              loadingRowsCount={loadingRowsCount ?? 5}
              tableColumns={tableColumns}
            />
          ) : (
            <tbody className={styles.tbody}>
              {table
                .getRowModel()
                .rows.slice(0, countToShow)
                .map((row) => (
                  <tr
                    key={row.id}
                    ref={callbackRef}
                    tabIndex={0}
                    style={{
                      cursor: cursor ? 'pointer' : 'auto',
                    }}
                    onClick={() => onRowClick?.(row)}
                    onFocus={() => {
                      setHoveredRow(row.id);
                    }}
                    onFocusCapture={() => {
                      setHoveredRow('');
                    }}
                    onMouseEnter={() => {
                      setHoveredRow(row.id);
                    }}
                    onMouseLeave={() => {
                      setHoveredRow('');
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const isHovered = hoveredRow === row.id;

                      return (
                        <td
                          key={cell.id}
                          className={clsx(styles.tableData, {
                            [styles.hideInLaptop]:
                              cell.column.columnDef.meta?.hideInLaptop,
                            [styles.hideInTablet]:
                              cell.column.columnDef.meta?.hideInTablet,
                            [styles.hideInTabletS]:
                              cell.column.columnDef.meta?.hideInTabletS,
                            [styles.hideInMobileL]:
                              cell.column.columnDef.meta?.hideInMobileL,
                            [styles.hideInMobile]:
                              cell.column.columnDef.meta?.hideInMobile,
                          })}
                        >
                          <span className={styles.tdContent}>
                            <Typography
                              as='span'
                              className={styles.desktop}
                              fontWeight={400}
                              variant='system_h4'
                            >
                              {isHovered &&
                              cell.column.columnDef.meta?.onHoverComponent
                                ? cell.column.columnDef.meta?.onHoverComponent
                                : flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                            </Typography>

                            <span className={styles.mobile}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </span>
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          )}
        </>
      )}
    </table>
  );
};

export default StyledTable;
