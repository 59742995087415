'use client';

import { Fragment, useState } from 'react';

import type { FC } from 'react';

import type {
  IMainMenuItem,
  ISubItem,
} from '../../../shared/types/dropdown-list';
import DropdownList from '../../dropdown-list/dropdown-list';
import { DropdownMenu } from '../../index';
import TabItem from '../tab-item';
import type { ITabSubItem } from '../tabs.types';
import TabDropdownItem from './tab-dropdown-item/tab-dropdown-item';

import IconShape from '../../../public/icons/icons16/chevron-down.svg';

import styles from './tab-dropdown.module.scss';

interface ITabDropdownProps {
  id: string;
  items: ITabSubItem[];
  selectTab: () => void;
  title: string;
}

/**
 * Компонент выпадающего списка вкладок.
 * @component
 *
 * @param {object} props - Свойства компонента.
 * @param {React.ReactNode} props.children - Внутреннее содержимое выпадающего списка.
 * @param {React.ReactNode} props.parent - Родительский элемент, открывающий выпадающий список.
 *
 * @returns {ReactNode} Компонент выпадающего списка вкладок.
 */

export const TabDropdown: FC<ITabDropdownProps> = ({ items, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openSubMenu, setOpenSubMenu] = useState<ISubItem | null>(null);

  const trans = (value?: string) =>
    value ? (value.length > 3 ? `${value}` : value) : '';

  const handleCloseMenu = () => setIsOpen(false);

  const getSubItem = (title: string, item: ITabSubItem[]) => {
    if (!item?.some((subItem) => subItem.subItems))
      return {
        el: (
          <div className={styles.sub_list}>
            {item?.map((subItem) => (
              <Fragment key={`${subItem.label}-${subItem.link}`}>
                <TabDropdownItem
                  hasChildren={false}
                  icon={subItem.icon}
                  link={subItem.link}
                  title={trans(subItem.label)}
                  description={
                    subItem.description ? trans(subItem.description) : undefined
                  }
                  onClick={handleCloseMenu}
                />
                {subItem.divider && (
                  <div className={styles.divider} tabIndex={-1} />
                )}
              </Fragment>
            ))}
          </div>
        ),
        title: trans(title),
      };

    return {
      subItem: item.map((subItem) => ({
        el: (
          <>
            <TabDropdownItem
              hasChildren={!!subItem.subItems}
              icon={subItem.icon}
              link={subItem?.link}
              title={trans(subItem.label)}
              description={
                subItem?.description ? trans(subItem.description) : undefined
              }
            />
          </>
        ),
        subItem: subItem?.subItems
          ? {
              el: (
                <div className={styles.sub_list}>
                  {subItem.subItems?.map((item) => (
                    <>
                      <TabDropdownItem
                        key={`${item.label}-${item.link}`}
                        hasChildren={false}
                        icon={item.icon}
                        link={item.link}
                        title={trans(item.label)}
                        description={
                          item.description ? trans(item.description) : undefined
                        }
                        onClick={handleCloseMenu}
                      />
                      {item.divider && (
                        <div className={styles.divider} tabIndex={-1} />
                      )}
                    </>
                  ))}
                </div>
              ),
              title: trans(subItem.label),
            }
          : undefined,
        title: trans(title),
      })),
      title: trans(title),
    };
  };

  const mainListBase: IMainMenuItem[] = items
    ?.map((item: ITabSubItem) => {
      const element = {
        el: (
          <>
            <TabDropdownItem
              description={trans(item?.description)}
              hasChildren={!!item?.subItems}
              icon={item.icon}
              link={item.link}
              title={trans(item.label)}
              onClick={handleCloseMenu}
            />
          </>
        ),
        subItem: item.subItems
          ? getSubItem(item.label, item.subItems)
          : undefined,
      };

      if (item.divider)
        return [
          element,
          { el: <div className={styles.divider} tabIndex={-1} /> },
        ];

      return element;
    })
    .flat();

  return (
    <DropdownMenu
      className={styles.dropdown}
      isOpen={isOpen}
      offset={-8}
      placement='bottom-start'
      setIsOpen={setIsOpen}
      parent={() => (
        <TabItem
          isOpen={isOpen}
          rightIcon={<IconShape />}
          onClick={() => setIsOpen(false)}
        >
          <span>{title}</span>
        </TabItem>
      )}
      onCloseEvent={() => setOpenSubMenu(null)}
    >
      <DropdownList
        mainLinks={mainListBase}
        openSubMenuList={openSubMenu}
        setOpenSubMenuList={setOpenSubMenu}
      />
    </DropdownMenu>
  );
};

export default TabDropdown;
