import { useEffect, useRef } from 'react';

import cn from 'clsx';
import type { Dispatch, SetStateAction } from 'react';

import styles from './pin-input.module.scss';

interface IPinInput {
  errors: string;
  isLoading: boolean;
  pinDigits: string[];
  setPinDigits: Dispatch<SetStateAction<string[]>>;
}

const PinInput = ({
  pinDigits,
  setPinDigits,
  errors,
  isLoading,
}: IPinInput) => {
  //Проверяем, поддерживает ли браузер правило для скрытия текста
  const isSupportTextSecurity = window.CSS.supports(
    '(-webkit-text-security: disc)'
  );

  const length = pinDigits.length;
  // здесь будут ссылки на input-элементы
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (index: number, newValue: string) => {
    const oldDigit = pinDigits[index];
    // старую цифру из поля ввода убираем, оставляя только новую
    const newDigit = newValue.trim().replace(oldDigit, '');

    if (newDigit < '0' || newDigit > '9') return;
    // теперь вызываем callback родителя, чтобы обовить pinDidigts
    const newDigits = [...pinDigits]; // копия pinDigits

    newDigits[index] = newDigit;
    setPinDigits(newDigits);

    if (index < length - 1) {
      inputRefs?.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    if (errors) {
      inputRefs.current[0].focus();
    }
  }, [errors]);

  useEffect(() => {
    if (pinDigits[0] === '') {
      inputRefs.current[0].focus();
    }
  }, [pinDigits[0]]);

  window.CSSSupportsRule;

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace') {
      e.preventDefault();

      if (pinDigits[index] === '') {
        for (let i = index - 1; i >= 0; i--) {
          if (pinDigits[i]) {
            const newDigits = [...pinDigits];

            newDigits[i] = '';
            setPinDigits(newDigits);
            inputRefs.current[i]?.focus();

            return;
          }
        }
      } else {
        const newDigits = [...pinDigits];

        newDigits[index] = '';
        setPinDigits(newDigits);
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  return (
    <form className={styles.pin__wrapper}>
      {pinDigits.map((digit, index) => (
        <input
          key={`pin-digit-input-${index + 1}`}
          ref={(element) => {
            if (element) inputRefs.current[index] = element;
          }}
          autoComplete={isSupportTextSecurity ? 'off' : 'new-password'}
          disabled={isLoading}
          type={isSupportTextSecurity ? 'text' : 'password'}
          value={digit}
          className={cn(styles.pin__input, {
            [styles.error]: errors,
            [styles.loading]: isLoading,
          })}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
        />
      ))}
    </form>
  );
};

export default PinInput;
