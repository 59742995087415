import type { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

import type { IAutosizeInputProps } from '../autosize-input/autosize-input';
import type { FontWeight, Variant } from '../typography/types';

export type Variants = 'normal' | 'compact';

export interface IInputProps
  extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  autosize?: boolean;
  autosizeProps?: IAutosizeInputProps;
  blockRight?: ReactNode;
  containerClassName?: string;
  decimal?: number;
  error?: string;
  hintText?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  rightLabel?: string | ReactNode;
  trim?: boolean;
  variant?: Variants;
}

export interface TypographyProps {
  fontWeight: FontWeight;
  variant: Variant;
}

export interface IVariantsTypography {
  error: TypographyProps;
  label: TypographyProps;
}

const errorBase: Pick<IVariantsTypography, 'error'> = {
  error: {
    fontWeight: 400,
    variant: 'system_h5',
  },
};

export const variantsTypography: Record<Variants, IVariantsTypography> = {
  compact: {
    label: {
      fontWeight: 600,
      variant: 'system_h5',
    },
    ...errorBase,
  },
  normal: {
    label: {
      fontWeight: 600,
      variant: 'system_h5',
    },
    ...errorBase,
  },
};
