'use client';

import { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';

import {
  useKeyPress,
  useMediaQuery,
  useOnClickOutside,
  useScrollLock,
  useTextDirection,
} from '../../shared/hooks';
import type { Locale } from '../../shared/types/navigation';

import styles from './burger-menu-container.module.scss';

const openTransition = { damping: 35, stiffness: 450, type: 'spring' };
const globalTransition = { duration: 0.3 };

const burgerVariantsLeft = {
  closed: (custom: { isRtl: boolean; width: string }) =>
    custom.isRtl
      ? { transition: globalTransition, x: `${custom.width}` }
      : { transition: globalTransition, x: `-${custom.width}` },
  open: { transition: { ...globalTransition, ...openTransition }, x: 0 },
};

const burgerVariantsRight = {
  closed: (custom: { isRtl: boolean; width: string }) =>
    custom.isRtl
      ? { transition: globalTransition, x: `-${custom.width}` }
      : { transition: globalTransition, x: `${custom.width}` },
  open: { transition: { ...globalTransition, ...openTransition }, x: 0 },
};

const shadowVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

export interface IBurgerMenuContainerProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  align?: 'left' | 'right';
  locale?: Locale;
  onCloseEvent?: () => void;
}

const BurgerMenuContainer: FC<IBurgerMenuContainerProps> = ({
  isOpen,
  setIsOpen,
  onCloseEvent,
  children,
  align = 'left',
  locale = 'en',
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { lockScroll, unlockScroll } = useScrollLock();
  const isTablet = useMediaQuery('tablet');
  const isTabletS = useMediaQuery('tabletS');
  const [curMenuWidth, setCurMenuWidth] = useState<string>('480px');
  const direction = useTextDirection(locale);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  useOnClickOutside(menuRef, handleCloseMenu);

  useKeyPress('Escape', handleCloseMenu);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node))
        handleCloseMenu();
    };

    if (typeof document !== 'undefined') {
      document.addEventListener('focus', handleFocus, true);

      return () => document.removeEventListener('focus', handleFocus, true);
    }
  }, [menuRef.current]);

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    const keepFocus = (e: FocusEvent) => {
      const target = e.relatedTarget as HTMLElement | null;

      if (menuRef?.current && target === null) {
        setTimeout(() => {
          e.preventDefault();
          menuRef?.current?.focus();
        }, 0);
      }
    };

    menuRef.current.addEventListener('focusout', keepFocus);

    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener('focusout', keepFocus);
      }
    };
  }, [menuRef.current]);

  useEffect(() => {
    if (isTabletS) setCurMenuWidth('100%');
    else if (isTablet) setCurMenuWidth('438px');
    else setCurMenuWidth('480px');
  }, [isTablet, isTabletS, lockScroll, unlockScroll]);

  useEffect(() => {
    if (isOpen) lockScroll();
    else unlockScroll();

    return () => unlockScroll();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) onCloseEvent?.();
  }, [isOpen]);

  if (typeof document === 'undefined') return null;

  return (
    <AnimatePresence mode='wait'>
      {isOpen && (
        <>
          <motion.div
            animate='open'
            className={styles.shadow_wrapper}
            exit='closed'
            initial='closed'
            transition={{ duration: 0.4, type: 'tween' }}
            variants={shadowVariants}
          />
          <motion.div
            ref={menuRef}
            animate='open'
            custom={{ isRtl: direction === 'rtl', width: curMenuWidth }}
            exit='closed'
            initial='closed'
            style={{ width: curMenuWidth }}
            tabIndex={0}
            className={clsx(styles.burger, {
              [styles.right]: align === 'right',
            })}
            variants={
              align === 'left' ? burgerVariantsLeft : burgerVariantsRight
            }
          >
            <div className={styles.burger__container}>
              <div className={styles.burger__wrapper}>{children}</div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default BurgerMenuContainer;
