'use client';
import React, { useId } from 'react';

import clsx from 'clsx';
import type { ReactNode } from 'react';

import IconCheck from '../../public/icons/icons16/icon-check.svg';
import IconIndeterminate from '../../public/icons/icons16/icon-indeterminate.svg';

import styles from './checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  fullWidth?: boolean;
  indeterminate?: boolean;
  label?: string | ReactNode;
  labelClassName?: string;
  labelPosition?: 'left' | 'right';
  rounded?: boolean;
}

export const Checkbox = React.forwardRef(
  (
    {
      label,
      indeterminate,
      labelPosition,
      fullWidth,
      className,
      rounded = false,
      labelClassName,
      ...props
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const id = useId();

    return (
      <div
        className={clsx(
          styles.custom_checkbox_wrapper,
          { [styles.fullWidth]: fullWidth },
          className
        )}
      >
        <input
          ref={ref}
          readOnly
          id={id}
          type='checkbox'
          className={clsx(styles.custom_checkbox, {
            [styles.indeterminate]: indeterminate,
            [styles.leftLabel]: labelPosition === 'left',
            [styles.fullWidth]: fullWidth,
          })}
          {...props}
        />
        <label
          className={clsx(labelClassName, { [styles.rounded]: rounded })}
          htmlFor={id}
        >
          <div className={styles.square}>
            <span className={styles.icon}>
              {indeterminate ? <IconIndeterminate /> : <IconCheck />}
            </span>
          </div>
          {label}
        </label>
      </div>
    );
  }
);

export default Checkbox;
