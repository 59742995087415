'use client';

import clsx from 'clsx';
import type React from 'react';
import type { PropsWithChildren } from 'react';

import styles from './icon-wrapper.module.scss';

export interface IIconWrapperProps {
  appearance?:
    | 'card'
    | 'table'
    | 'footer'
    | 'primary'
    | 'inverted'
    | 'select'
    | 'header'
    | 'outlined'
    | 'advertisement'
    | 'stroked'
    | 'neutral_20'
    | 'success'
    | 'ghost';
  className?: string;
  size?: 'extra_small' | 'small' | 'medium' | 'big' | 'very_big' | 'extra_big';
}

type TIconWrapper = PropsWithChildren<IIconWrapperProps> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const IconWrapper = ({
  children,
  appearance = 'primary',
  size = 'medium',
  className,
  onClick,
}: TIconWrapper) => (
  <div
    className={clsx(
      styles.icon_wrapper,
      styles[size],
      styles[appearance],
      className,
      'select',
      {
        [styles.action]: onClick,
      }
    )}
    onClick={(e) => onClick?.(e)}
  >
    {children}
  </div>
);

export default IconWrapper;
