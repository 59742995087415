import styles from './fiat-icon.module.scss';

interface FiatIconProps {
  symbolCode: string;
}

export const FiatIcon = ({ symbolCode }: FiatIconProps) => {
  let fontSize = 16;

  if (symbolCode.length > 1) {
    fontSize -= symbolCode.includes('ʻ')
      ? symbolCode.length * 1.8
      : symbolCode.length * 2;
  }

  return (
    <span className={styles.fiatIcon} style={{ fontSize: `${fontSize}px` }}>
      {symbolCode}
    </span>
  );
};

export default FiatIcon;
