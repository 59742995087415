import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';

const variants = {
  active: ({ height }: { height: number }) => ({
    height: height,
    opacity: 1,
    overflow: 'hidden',
    x: 0,
  }),
};

/**
 * AnimatedFrames Component
 *
 * React component for rendering animated frames with framer-motion based on height changes.
 *
 * @component
 *
 * @example
 * // Example usage of AnimatedFrames component
 * <AnimatedFrames isOpenSubMenu={isSubMenuOpen}>
 *   {frameContent}
 * </AnimatedFrames>
 *
 * @param {Object} props - The properties of the AnimatedFrames component.
 * @param {ReactNode} props.children - The content to be displayed within the animated frame.
 *
 * @returns {ReactNode} - Rendered AnimatedFrames component.
 */
export const AnimatedFrames = ({ children }: { children: React.ReactNode }) => {
  const [ref, { height }] = useMeasure();

  return (
    <motion.div
      animate='active'
      custom={{ height }}
      style={{ position: 'relative', width: '100%' }}
      transition={{ duration: 0.1 }}
      variants={variants}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
};

export default AnimatedFrames;
