'use client';

import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import type React from 'react';
import type { FC, InputHTMLAttributes, ReactNode } from 'react';

import { stepperDecrement, stepperIncrement } from '../../shared/lib';
import Typography from '../typography';

import styles from './autosize-input.module.scss';

const copyStyles = (styles: CSSStyleDeclaration, node: HTMLElement) => {
  node.style.fontSize = styles.fontSize;
  node.style.fontFamily = styles.fontFamily;
  node.style.fontWeight = styles.fontWeight;
  node.style.fontStyle = styles.fontStyle;
  node.style.letterSpacing = styles.letterSpacing;
  node.style.textTransform = styles.textTransform;
};

const FORBIDDEN_SYMBOLS = new Set(['+', '-', 'ArrowUp', 'ArrowDown']);

export interface IAutosizeInputProps {
  floatingElement?: ReactNode;
  gap?: number;
  inputClassName?: string;
  minWidth?: number;
  onAutosize?: (newWidth: string | number) => void;
  strictInputLimit?: boolean;
  tertiaryPlaceholder?: boolean;
}

export type AutosizeInputProps = IAutosizeInputProps &
  InputHTMLAttributes<HTMLInputElement>;

const AutosizeInput: FC<AutosizeInputProps> = ({
  minWidth,
  gap = 2,
  onAutosize,
  inputClassName,
  tertiaryPlaceholder,
  floatingElement,
  strictInputLimit = false,
  ...props
}) => {
  const sizerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const [inputWidth, setInputWidth] = useState(minWidth);
  const isInputFilled = props.value || props.value === 0;

  const {
    step,
    min,
    max,
    autoFocus,
    value,
    placeholder,
    defaultValue,
    className,
    style,
    inputMode,
  } = props;

  const propsForHelpers = { max, min, step };

  const decimal = useMemo(() => {
    let result = 0;

    if (step && step.toString().includes('.')) {
      result = step.toString().split('.').pop()?.length || 0;
    }

    return result;
  }, [value, step]);

  const updateInputWidth = () => {
    if (!sizerRef.current || sizerRef.current.scrollWidth === undefined) {
      return;
    }

    let newInputWidth;

    // + 2 is added to stabilize input, without it text goes to the left sometimes
    newInputWidth =
      placeholder && !value
        ? Math.max(sizerRef.current.scrollWidth) + 2
        : sizerRef.current.scrollWidth + 2;

    if (minWidth && newInputWidth < minWidth) {
      newInputWidth = minWidth;
    }

    if (newInputWidth !== inputWidth) {
      setInputWidth(newInputWidth);
      onAutosize?.(newInputWidth);
    }
  };

  const copyInputStyles = () => {
    if (!window.getComputedStyle) {
      return;
    }

    const inputStyles =
      inputRef.current && window.getComputedStyle(inputRef.current);

    if (!inputStyles) {
      return;
    }

    if (sizerRef.current) {
      copyStyles(inputStyles, sizerRef.current);
    }
  };

  const sizerValue = [defaultValue, value, ''].reduce(
    (previousValue, currentValue) => {
      if (previousValue !== null && previousValue !== undefined) {
        return previousValue;
      }

      return currentValue;
    }
  );

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.type !== 'number') return;

    if (FORBIDDEN_SYMBOLS.has(e.key)) e.preventDefault();

    // if (!props.value) return;
    const valueString = value?.toString();
    const isNegative = valueString?.startsWith('-');
    let newValue;

    if (e.key === '-' && !isNegative) newValue = `-${value}`;
    else if (e.key === '+' && isNegative) newValue = valueString?.slice(1);
    else if (e.key === 'ArrowUp' && inputRef?.current?.value)
      newValue = stepperIncrement({
        decimal,
        inputValue: inputRef?.current?.value,
        props: propsForHelpers,
      });
    else if (e.key === 'ArrowDown' && inputRef?.current?.value)
      newValue = stepperDecrement({
        decimal,
        inputValue: inputRef?.current?.value,
        props: propsForHelpers,
      });

    if (newValue) {
      props.onChange?.({
        target: { value: newValue },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  // const handleWheel = (e: WheelEvent) => {
  //   if (props.type !== 'number') return;
  //
  //   if (document.activeElement !== inputRef.current) return;
  //
  //   e.preventDefault();
  //   let value;
  //
  //   if (e.deltaY < 0 && inputRef?.current?.value)
  //     value = stepperIncrement({
  //       decimal,
  //       inputValue: inputRef?.current?.value,
  //       props: propsForHelpers,
  //     });
  //   else if (e.deltaY > 0 && inputRef?.current?.value)
  //     value = stepperDecrement({
  //       decimal,
  //       inputValue: inputRef?.current?.value,
  //       props: propsForHelpers,
  //     });
  //   props.onChange?.({
  //     target: { value },
  //   } as React.ChangeEvent<HTMLInputElement>);
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value !== '-') {
      const value = Number(e.target.value);

      if (props.type === 'number' && Number.isNaN(value)) return;

      if (strictInputLimit && (value < Number(min) || value > Number(max))) {
        return;
      }
    }

    props.onChange?.(e);
  };

  useEffect(() => {
    copyInputStyles();
    // if (props.type === 'number') {
    //   wrapperRef.current?.addEventListener('wheel', handleWheel);
    // }

    // return () => wrapperRef.current?.removeEventListener('wheel', handleWheel);
  }, [wrapperRef.current]);

  useLayoutEffect(() => {
    updateInputWidth();
  }, [props, minWidth, inputClassName]);

  useEffect(() => {
    autoFocus && inputRef.current?.focus();
  }, [inputRef.current]);

  return (
    <>
      <div
        ref={wrapperRef}
        className={clsx(className, styles.inputWrapper, {
          [styles.limitWidth]: isInputFilled,
        })}
        style={
          {
            '--sizerWidth': `${placeholderRef.current?.scrollWidth}px`,
            gap: isInputFilled ? `${gap}px` : '0px',
            ...style,
          } as React.CSSProperties & Record<string, string>
        }
      >
        <input
          {...props}
          ref={inputRef}
          inputMode={props.type === 'number' ? 'numeric' : inputMode}
          placeholder=''
          type={props.type === 'number' ? 'text' : props.type}
          value={value}
          className={clsx(inputClassName, styles.input, {
            [styles.hiddenInput]: isInputFilled && !inputWidth,
          })}
          style={{
            boxSizing: 'content-box',
            width: `${inputWidth || 2}px`,
          }}
          onChange={handleChange}
          onKeyDown={handleKeydown}
        />
        {!inputWidth && isInputFilled && (
          <span
            className={clsx(inputClassName, styles.input, styles.fakeInput)}
          >
            {value}
          </span>
        )}
        <Typography
          as='span'
          fontWeight={400}
          variant='system_h4'
          className={clsx(styles.placeholder, {
            [styles.empty]: !isInputFilled,
            [styles.tertiaryPlaceholder]: tertiaryPlaceholder,
          })}
          onClick={() => inputRef?.current?.focus()}
        >
          {isInputFilled ? floatingElement : placeholder}
        </Typography>
        <div ref={sizerRef} className={styles.sizer}>
          {sizerValue}
        </div>
      </div>
    </>
  );
};

export default AutosizeInput;
