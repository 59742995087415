'use client';

import { useMemo, useState } from 'react';

import clsx from 'clsx';
import type { FC } from 'react';

import { useTextDirection } from '../../shared/hooks';
import type {
  IDropdownListProps,
  IMainMenuItem,
  ISubItem,
} from '../../shared/types/dropdown-list';
import { AnimatedFrames, MenuAnimation, Typography } from '../index';

import ArrowIcon from '../../public/icons/icons16/chevron-left.svg';

import styles from './dropdown-list.module.scss';

const transition = { duration: 0.4, ease: [0.25, 0.1, 0.25, 1] };

const mainMenuItemVariants = {
  hidden: (isRtl: boolean) => (isRtl ? { x: '210%' } : { x: '-210%' }),
  visible: { x: 0 },
};

const subMenuItemVariants = {
  hidden: (isRtl: boolean) => (isRtl ? { x: '-210%' } : { x: '210%' }),
  visible: { x: 0 },
};

/**
 * DropdownList Component
 *
 * React component for rendering a dropdown menu with customizable animations.
 *
 * @component
 *
 * @example
 * // Example usage of DropdownList component
 * <DropdownList
 *   mainLinks={mainLinksArray}
 *   openSubMenuList={openSubMenuListArray}
 *   setOpenSubMenuList={setOpenSubMenuListFunction}
 * />
 *
 * @param {Object} props - The properties of the DropdownList component.
 * @param {mainMenuItem[]} props.mainLinks - An array of main menu items.
 * @param {ISubItem | null} props.openSubMenuList - An array of sub-menu items or null
 * if there is no open sub-menu.
 * @param {function} props.setOpenSubMenuList - Function to set the open sub-menu list.
 * @param {boolean} props.burger - Has big padding (32px) for subItem arrow (16px on tabletS)
 * @returns {ReactNode} - Rendered DropdownList component.
 */

const DropdownList: FC<IDropdownListProps> = ({
  mainLinks,
  openSubMenuList,
  setOpenSubMenuList,
  burger = false,
  hasChildren = false,
}) => {
  const [openNestedSubMenuItem, setOpenNestedSubMenuItem] =
    useState<ISubItem | null>(null);

  const direction = useTextDirection();

  const isOpenSubMenu = useMemo(
    () =>
      !!openSubMenuList &&
      (!!openSubMenuList?.el || !!openSubMenuList?.subItem),
    [openSubMenuList]
  );

  const isOpenNestedSubMenu = useMemo(
    () => !!openNestedSubMenuItem && !!openNestedSubMenuItem?.el,
    [openNestedSubMenuItem]
  );

  const handleMenuItemClick = (link: IMainMenuItem) => {
    if (link.subItem) {
      setOpenSubMenuList(link.subItem);
    }
  };

  return (
    <AnimatedFrames>
      <MenuAnimation
        isMainMenu
        custom={direction === 'rtl'}
        initial={hasChildren ? 'hidden' : 'visible'}
        isOpen={!isOpenSubMenu}
        transition={transition}
        variants={
          hasChildren && !isOpenSubMenu
            ? subMenuItemVariants
            : mainMenuItemVariants
        }
      >
        <div autoFocus className={styles.mainList}>
          {mainLinks?.map((link) => (
            <div
              key={`item-${link}`}
              className={styles.mainList__item}
              onClick={() => handleMenuItemClick(link)}
              {...{
                inert: isOpenNestedSubMenu || isOpenSubMenu ? true : undefined,
              }}
            >
              {link.el}
              {'subItem' in link && link?.subItem && (
                <ArrowIcon
                  className={clsx(styles.arrow, {
                    [styles.big_padding]: burger,
                  })}
                />
              )}
            </div>
          ))}
        </div>
      </MenuAnimation>
      <MenuAnimation
        custom={direction === 'rtl'}
        exit='hidden'
        initial='hidden'
        isOpen={isOpenSubMenu}
        transition={transition}
        variants={
          isOpenNestedSubMenu ? mainMenuItemVariants : subMenuItemVariants
        }
      >
        <div className={styles.subItem}>
          {isOpenSubMenu && !isOpenNestedSubMenu && (
            <button
              tabIndex={isOpenSubMenu ? 0 : -1}
              className={clsx(styles.subItem__back_btn, {
                [styles.burger]: burger,
              })}
              onClick={() => {
                setOpenSubMenuList(null);
              }}
            >
              <ArrowIcon />
              <Typography as='p' variant='system_h4'>
                {openSubMenuList?.title}
              </Typography>
            </button>
          )}
          {openSubMenuList?.subItem ? (
            <DropdownList
              hasChildren
              burger={burger}
              mainLinks={openSubMenuList?.subItem}
              openSubMenuList={openNestedSubMenuItem}
              setOpenSubMenuList={setOpenNestedSubMenuItem}
            />
          ) : (
            openSubMenuList?.el
          )}
        </div>
      </MenuAnimation>
    </AnimatedFrames>
  );
};

export default DropdownList;
