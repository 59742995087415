import type { RefObject } from 'react'; //TODO TOAST CONTAINER!!
import { toast } from 'react-toastify';

type CopyFn = (text?: string) => void;

export const useCopyToClipboard = (
  ref: RefObject<HTMLElement>,
  message: string,
  textToCopy?: string
): [CopyFn, boolean] => {
  const navigator =
    typeof window === 'undefined' ? undefined : window?.navigator;

  const hasClipboardApi = !!navigator?.clipboard?.writeText;

  const copyText: CopyFn = (text) => {
    let res = '';

    if (text) {
      res = text;
    } else if (textToCopy) {
      res = textToCopy;
    } else if (ref?.current?.textContent !== '') {
      res = ref?.current?.textContent ?? '';
    }

    if (hasClipboardApi) {
      navigator.clipboard.writeText(res || '');
      toast.success(message);
    }
  };

  return [copyText, hasClipboardApi];
};
