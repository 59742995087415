'use client';

import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';
import type React from 'react';
import type { ReactNode } from 'react';

import { useKeyPress, useScrollLock } from '../../shared/hooks';
import { Typography } from '../index';

import IconClose from '../../public/icons/icons24/close.svg';

import styles from './modal.module.scss';

export interface IModalProps {
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  isActive?: boolean;
  maxWidth?: string;
  noPadding?: boolean;
  title?: string | ReactNode;
  titleClassName?: string;
}

const Modal: React.FC<IModalProps> = ({
  title,
  onClose,
  children,
  isActive,
  maxWidth,
  className,
  noPadding = false,
  titleClassName,
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { lockScroll, unlockScroll } = useScrollLock();

  const handleOnClickOutside = (event: MouseEvent) => {
    const node = event.target as HTMLElement;

    if (
      ref.current &&
      node.contains(ref.current) &&
      node.className.includes('backdrop')
    ) {
      onClose();
    }
  };

  useKeyPress('Escape', onClose);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    ref.current?.focus();

    if (isActive) {
      lockScroll();
      document.addEventListener('mousedown', handleOnClickOutside);
    } else {
      unlockScroll();
      document.removeEventListener('mousedown', handleOnClickOutside);
    }

    return () => unlockScroll();
  }, [isActive, lockScroll, unlockScroll, mounted]);

  return (
    mounted &&
    createPortal(
      <AnimatePresence>
        {isActive && (
          <FocusTrap>
            <motion.div
              animate={{ opacity: 1 }}
              className={styles.modal_backdrop}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                ref={ref}
                aria-modal
                animate={{ opacity: 1, y: 0 }}
                className={clsx(styles.wrapper, className)}
                exit={{ opacity: 0, y: 50 }}
                id='modal'
                initial={{ opacity: 0, y: 50 }}
                role='dialog'
                style={{ maxWidth: maxWidth ?? 'unset' }}
                transition={{ duration: 0.3, type: 'spring' }}
              >
                <div
                  className={clsx(styles.modalHeader, {
                    [styles.no_title]: !title,
                    [styles.custom_header]: typeof title !== 'string',
                  })}
                >
                  {typeof title === 'string' ? (
                    <Typography
                      as='h2'
                      className={titleClassName}
                      fontWeight={600}
                      variant='system_h3'
                    >
                      {title}
                    </Typography>
                  ) : (
                    title
                  )}
                  <button
                    className={styles.icon}
                    type='button'
                    onClick={onClose}
                  >
                    <IconClose />
                  </button>
                </div>
                <div
                  className={clsx(styles.modalBody, {
                    [styles.no_padding]: noPadding,
                  })}
                >
                  {children}
                </div>
              </motion.div>
            </motion.div>
          </FocusTrap>
        )}
      </AnimatePresence>,
      document.body
    )
  );
};

export default Modal;
