import type { Locale } from '../types/navigation';

export const useTextDirection = (locale?: Locale) => {
  enum RTL_LANG_LIST {
    'ae',
    'ar',
    'arc',
    'bcc',
    'bqi',
    'ckb',
    'dv',
    'fa',
    'glk',
    'he',
    'ku',
    'mzn',
    'nqo',
    'pnb',
    'ps',
    'sd',
    'ug',
    'ur',
  }

  if (!locale) locale = 'en';

  return RTL_LANG_LIST[locale as keyof typeof RTL_LANG_LIST] ? 'rtl' : 'ltr';
};
