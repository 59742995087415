'use client';

import clsx from 'clsx';
import { motion, type Variants } from 'framer-motion';
import type { PropsWithChildren, ReactNode } from 'react';

import IconArrow from '../../public/icons/icons24/chevron-down.svg';
import MinusIcon from '../../public/icons/icons24/minus.svg';
import PlusIcon from '../../public/icons/icons24/plus.svg';

import styles from './accordion.module.scss';

const variants: Variants = {
  closed: {
    height: 0,
  },
  open: {
    height: 'auto',
  },
};

export type TAccordionVariants = 'bordered' | 'card' | 'custom';
export type TTriggerIcon = 'arrow' | 'plus' | ReactNode;

export interface IAccordionProps {
  isOpen: boolean;
  onClick: () => void;
  title: string | ReactNode;
  classNames?: {
    button?: string;
    content?: string;
    contentWrapper?: string;
    header?: string;
    title?: string;
    wrapper?: string;
  };
  fullWidth?: boolean;
  icon?: TTriggerIcon;
  leftContent?: ReactNode | string;
  variant?: TAccordionVariants;
}

export type TAccordionProps = PropsWithChildren<IAccordionProps>;

const Accordion = ({
  leftContent,
  title,
  isOpen,
  onClick,
  children,
  classNames,
  variant = 'bordered',
  icon = variant === 'bordered' ? 'plus' : 'arrow',
  fullWidth = true,
}: TAccordionProps) => {
  const triggerIcon =
    typeof icon === 'string' && ['arrow', 'plus'].includes(icon) ? (
      icon === 'plus' ? (
        isOpen ? (
          <MinusIcon />
        ) : (
          <PlusIcon />
        )
      ) : (
        <IconArrow
          className={clsx(styles.accordion__arrow, {
            [styles.accordion__arrow_open]: isOpen,
          })}
        />
      )
    ) : (
      icon
    );

  return (
    <div
      className={clsx(styles.accordion, classNames?.wrapper, styles[variant], {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <button
        className={clsx(styles.accordion__button, classNames?.button)}
        onClick={onClick}
      >
        <div className={clsx(styles.accordion__header, classNames?.header)}>
          {leftContent}
          {title}
        </div>
        {icon && triggerIcon}
      </button>

      <motion.div
        animate={isOpen ? 'open' : 'closed'}
        initial='closed'
        variants={variants}
        className={clsx(
          classNames?.contentWrapper,
          styles.accordionContent__wrapper
        )}
      >
        <div className={clsx(styles.accordion__content, classNames?.content)}>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default Accordion;
