import { useCallback } from 'react';

import { isIOS } from '../lib';

export const useScrollLock = () => {
  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.maxHeight = '100dvh';

    if (isIOS()) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.maxHeight = 'none';

    if (isIOS()) {
      const scrollY = document.body.style.top;

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, Number.parseInt(scrollY || '0') * -1);
    }
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export default useScrollLock;
