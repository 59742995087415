'use client';

import { useRef } from 'react';

import type React from 'react';

import { useCopyToClipboard } from '../../shared/hooks';

import IconCopy from '../../public/icons/icons24/copy-2.svg';

import styles from './copy-caption.module.scss';

interface ICopyCaption {
  children: React.ReactNode;
  message: string;
}

const CopyCaption = ({ children, message }: ICopyCaption) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [copyText, hasClipboardApi] = useCopyToClipboard(ref, message);

  return (
    <div className={styles.share_block__copy} onClick={() => copyText()}>
      <span ref={ref} className={styles.copy__text}>
        {children}
      </span>
      {hasClipboardApi && (
        <div className={styles.copy__icon}>
          <IconCopy />
        </div>
      )}
    </div>
  );
};

export default CopyCaption;
