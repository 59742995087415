import clsx from 'clsx';
import type React from 'react';
import type { ButtonHTMLAttributes } from 'react';

import Typography from '../../typography';

import styles from './tab-item.module.scss';

interface ITabItem extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  isOpen?: boolean;
  rightIcon?: React.ReactNode;
}

/**
 * Компонент вкладки.
 * @component
 *
 * @param {object} props - Свойства компонента.
 * @param {React.ReactNode} props.children - Внутреннее содержимое вкладки.
 * @param {React.ReactNode} [props.rightIcon] - Иконка справа от содержимого вкладки.
 * @param {...ButtonHTMLAttributes<HTMLButtonElement>} props - Дополнительные свойства кнопки.
 *
 * @returns {ReactNode} Компонент вкладки.
 */

export const TabItem = ({
  children,
  rightIcon,
  isOpen,
  ...props
}: ITabItem) => (
  <button type='button' {...props} className={styles.tabButton} tabIndex={0}>
    <Typography as='span' className={styles.tabItem} variant='system_h4'>
      {children}
      {rightIcon && (
        <i className={clsx(styles.icon, { [styles.open]: isOpen })}>
          {rightIcon}
        </i>
      )}
    </Typography>
  </button>
);

export default TabItem;
