'use client';

import type { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';

import styles from '../styled-table.module.scss';

interface ILoadingTable<T> {
  loadingRowsCount: number;
  tableColumns: ColumnDef<T>[];
}

export const LoadingTable = <T extends object>({
  tableColumns,
  loadingRowsCount,
}: ILoadingTable<T>) => (
  <tbody className={styles.tbody}>
    {Array.from({ length: loadingRowsCount }, (_, i) => (
      <tr key={i}>
        {tableColumns.map((tc) => (
          <td
            key={tc.id}
            className={clsx(styles.tableData, {
              [styles.hideInTablet]: tc.meta?.hideInTablet,
              [styles.hideInLaptop]: tc.meta?.hideInLaptop,
            })}
          >
            {tc.meta?.skeletonProps?.columnSkeleton}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default LoadingTable;
