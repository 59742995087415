'use client';

import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import Button from '../button';
import FormField from '../form-field';
import type { FormFieldsArray } from './lib/form-field-creators/form-field-creators-types';
import type { IFormSubmitButtonProps } from './model/types';

interface IFormFactory<FormProps extends FieldValues> {
  fields: FormFieldsArray<FormProps>;
  formMethods: UseFormReturn<FormProps>;
  submitButtonProps: IFormSubmitButtonProps<FormProps>;
}

/**
 * Компонент обертки формы для работы с react-hook-form библиотеки.
 * @component
 * @param {FormFieldsArray<FormProps>} [fields] - массив обектов, описывающих элементы формы. Создаются с помощью предусмотренных для этого функций.
 * @param {UseFormReturn<FormProps>} [formMethods] - объект, создаваевый с помощью react-hook-form.
 * @param {IFormSubmitButtonProps<FormProps>} [submitButtonProps] - объект, описывающий кнопку submit. Включает в себя функцию onSubmit, текст кнопки, а также любые другие пропсы, относящиеся к элементу Button.
 * @returns {JSX.Element} Возвращает JSX-элемент формы, работающая совместно с react-hook-form.
 */

const FormFactory = <FormProps extends FieldValues>({
  fields,
  formMethods,
  submitButtonProps,
}: IFormFactory<FormProps>) => {
  const { onSubmit, submitButtonText, ...props } = submitButtonProps;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <FormField<FormProps> key={field.name} {...field} />
        ))}

        <Button
          disabled={!formMethods.formState.isValid}
          type='submit'
          {...props}
        >
          {submitButtonText ?? 'Submit'}
        </Button>
      </form>
    </FormProvider>
  );
};

export default FormFactory;
