import { clsx } from 'clsx';
import type React from 'react';
import type { ElementType } from 'react';

import { CSS_VARIABLES_META } from '../../shared/types/css-variables';
import type {
  FontWeight,
  TextDecoration,
  TextTransform,
  TextWrap,
  TTypographyProps,
} from './types';

import styles from './typography.module.scss';

const fontWeightMap: Record<FontWeight, string> = {
  200: styles.fontWeight_200,
  300: styles.fontWeight_300,
  400: styles.fontWeight_400,
  500: styles.fontWeight_500,
  600: styles.fontWeight_600,
  700: styles.fontWeight_700,
};

const textTransformMap: Record<TextTransform, string> = {
  capitalize: styles.textTransform_capitalize,
  fullWidth: styles.textTransform_fullWidth,
  lowercase: styles.textTransform_lowercase,
  none: styles.textTransform_none,
  uppercase: styles.textTransform_uppercase,
};

const textDecorationMap: Record<TextDecoration, string> = {
  lineThrough: styles.textDecoration_lineThrough,
  none: styles.textDecoration_none,
  overline: styles.textDecoration_overline,
  underline: styles.textDecoration_underline,
};

const textWrapMap: Record<TextWrap, string> = {
  balance: styles.textWrap_balance,
  nowrap: styles.textWrap_nowrap,
  pretty: styles.textWrap_pretty,
  wrap: styles.textWrap_wrap,
};

const Typography = <T extends ElementType = 'span'>({
  as,
  textTransform = 'none',
  textDecoration = 'none',
  textWrap = 'wrap',
  fontWeight,
  variant,
  children,
  className,
  isIgnoreRtl = false,
  isIgnoreMediaQuery = false,
  htmlProps,
  onClick,
  color,
}: TTypographyProps<T>) => {
  const Tag = as || 'p';

  const getClasses = clsx(styles.typography, styles[variant], className, {
    [fontWeightMap[fontWeight ?? 200]]:
      fontWeight &&
      Object.prototype.hasOwnProperty.call(fontWeightMap, fontWeight),
    [textTransformMap[textTransform]]:
      Object.prototype.hasOwnProperty.call(textTransformMap, textTransform) &&
      textTransform !== 'none',
    [textDecorationMap[textDecoration]]:
      Object.prototype.hasOwnProperty.call(textDecorationMap, textDecoration) &&
      textDecoration !== 'none',
    [textWrapMap[textWrap]]:
      Object.prototype.hasOwnProperty.call(textWrapMap, textWrap) &&
      textWrap !== 'wrap',
    [styles.disabled__rtl]: isIgnoreRtl,
    [styles.disabled__mediaQueryProps]: isIgnoreMediaQuery,
  });

  return (
    <Tag
      {...(color && { style: { color: CSS_VARIABLES_META[color] } })}
      className={getClasses}
      {...htmlProps}
      onClick={onClick as React.MouseEventHandler<unknown>}
    >
      {children}
    </Tag>
  );
};

export default Typography;
