'use client';
import { useId, useRef } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getLink } from '../../shared/lib';
import type { Locale } from '../../shared/types/navigation';
import { TabDropdown, TabItem } from '../index';
import type { ITab } from './tabs.types';

import 'swiper/css';
import styles from './tabs.module.scss';

interface ITabs {
  id: string;
  tabsData: ITab[];
  activeTabValue?: string | null;
  className?: string;
  isShowBorder?: boolean;
  locale?: Locale;
  onChange?: (value: string) => void;
  swiper?: boolean;
}

/**
 * Компонент вкладок (Tabs).
 * @component
 * @param {Object[]} tabsData - Массив объектов, представляющих данные вкладок.
 * @param {string} tabsData[].label - Текст метки вкладки.
 * @param {string} [tabsData[].link] - URL-адрес, на который будет произведен переход
 * при выборе вкладки.
 * @param {Object[]} [tabsData[].links] - Массив объектов, представляющих подвкладки вкладки.
 * @param {string} tabsData[].links[].label - Текст метки подвкладки.
 * @param {string} tabsData[].links[].link - URL-адрес, на который будет произведен переход
 * при выборе подвкладки.
 * @param {function} [onChange] - Функция обратного вызова, вызываемая
 * при выборе вкладки или подвкладки.
 * @param {Object} activeTabValue - Активная вкладка.
 * @param {string} activeTabValue.label - Текст метки активной вкладки.
 * @param {string} [activeTabValue.link] - URL-адрес активной вкладки.
 * @returns {ReactNode} Возвращает JSX-элемент вкладок.
 */

export const Tabs = ({
  tabsData,
  activeTabValue,
  onChange,
  id,
  className,
  isShowBorder,
  swiper = false,
  locale = 'en',
}: ITabs) => {
  const swiperRef = useRef<SwiperClass | null>(null);
  const uniqueId = useId();

  const handleSelectTab = (value: string) => {
    onChange?.(value);

    if (swiperRef?.current) {
      const index = tabsData.findIndex((item) => getLink(item.link) === value);

      index && swiperRef.current?.slideTo(index);
    }
  };

  const onSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  const getItem = (item: ITab) => {
    if (item?.label && item.link) {
      if (item.subitems) {
        return (
          <TabDropdown
            id={getLink(item.link)}
            items={item.subitems}
            selectTab={() => handleSelectTab(getLink(item.link))}
            title={item?.label?.length > 3 ? `${item?.label}` : item.label}
          />
        );
      }

      return (
        <TabItem onClick={() => handleSelectTab(getLink(item.link))}>
          <span>{item?.label}</span>
        </TabItem>
      );
    }

    if (item.label === 'divider') return <div className={styles.divider} />;
  };

  return swiper ? (
    <Swiper
      freeMode
      className={clsx(styles.swiper, className)}
      slidesPerView='auto'
      breakpoints={{
        0: { threshold: 0 },
        1024: { threshold: 20 },
      }}
      initialSlide={tabsData.findIndex(
        (item) => activeTabValue === getLink(item.link)
      )}
      onBeforeInit={onSwiperInit}
    >
      {tabsData.map((item, i) => (
        <SwiperSlide
          key={`${item?.link}-${item.label}`}
          className={clsx(styles.tabItems, {
            [styles.active]: activeTabValue === item?.link,
          })}
        >
          {getItem(item)}
          {activeTabValue && activeTabValue === getLink(item.link) && (
            <motion.div
              className={styles.underline}
              layoutId={`${uniqueId}-${id}-underline-${i}-${locale}`}
              style={{ originY: '0px' }}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <ul
      className={clsx(styles.tabs, className, {
        [styles.tabs_border]: isShowBorder,
      })}
    >
      {tabsData.map((item, i) => (
        <li key={`${item?.link}-${item.label}`} className={styles.tabItems}>
          {getItem(item)}
          {activeTabValue && activeTabValue === getLink(item.link) && (
            <motion.div
              className={styles.underline}
              layoutId={`${uniqueId}-${id}-underline-${i}-${locale}`}
              style={{ originY: '0px' }}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
