'use client';

import { useEffect, useRef } from 'react';

import { FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import type { PanInfo } from 'framer-motion';
import { motion, useDragControls } from 'framer-motion';
import type React from 'react';

import useScrollLock from '../../shared/hooks/use-scroll-lock';
import Typography from '../typography';

import IconShape from '../../public/icons/icons24/close.svg';

import styles from './mobile-menu-container.module.scss';

const ease = [0.36, 0.66, 0.04, 1];

const variantsMobile = {
  close: { transition: { duration: 0.3, ease }, y: '100%' },
  open: { transition: { duration: 0.4, ease }, y: 0 },
};

const variantsOverlay = {
  close: { opacity: 0, transition: { duration: 0.3, ease } },
  open: { opacity: 1, transition: { duration: 0.4, ease } },
};

export interface MobileMenuContainerProps {
  children: React.ReactNode;
  onCloseEvent: () => void;
  contentClassName?: string;
  mobileTitle?: string;
  withoutPortal?: boolean;
}

export const MobileMenuContainer = ({
  onCloseEvent,
  children,
  mobileTitle,
  withoutPortal,
  contentClassName,
}: MobileMenuContainerProps) => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const bgRef = useRef<HTMLDivElement>(null);
  const dragControls = useDragControls();

  const handleDragEnd = (_event: MouseEvent | TouchEvent, info: PanInfo) => {
    const shouldClose = info.offset.y > 100;

    if (shouldClose) {
      onCloseEvent();
    }
  };

  const handleDrag = (_event: MouseEvent | TouchEvent, info: PanInfo) => {
    if (bgRef.current) {
      bgRef.current.style.visibility = info.offset.y > 0 ? 'hidden' : 'visible';
    }
  };

  const checkWrapper = (children: React.ReactNode): React.ReactNode =>
    withoutPortal ? (
      <>{children}</>
    ) : (
      <FloatingPortal>{children}</FloatingPortal>
    );

  useEffect(() => {
    lockScroll();

    return () => unlockScroll();
  }, [lockScroll, unlockScroll]);

  return checkWrapper(
    <div className={styles.mobileOptions}>
      <motion.div
        animate='open'
        className={styles.optionsOverlay}
        exit='close'
        initial='close'
        variants={variantsOverlay}
        onClick={onCloseEvent}
      />

      <motion.div
        ref={bgRef}
        animate='open'
        className={styles.backgroundDrag}
        exit='close'
        initial='close'
        variants={variantsMobile}
      />

      <motion.div
        animate='open'
        className={clsx(styles.optionsContent, contentClassName)}
        drag='y'
        dragConstraints={{ bottom: 0, top: 0 }}
        dragControls={dragControls}
        dragElastic={{ bottom: 0.5, top: 0 }}
        dragListener={false}
        exit='close'
        initial='close'
        variants={variantsMobile}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        <motion.div
          className={clsx(styles.optionsHeader, 'dropdown_disableClose')}
          style={{ touchAction: 'none' }}
          onPointerDown={(e) => dragControls.start(e)}
        >
          {mobileTitle && (
            <Typography as='span' className={styles.title} variant='system_h3'>
              {mobileTitle}
            </Typography>
          )}

          <button className={styles.closeButton} onClick={onCloseEvent}>
            <IconShape />
          </button>
        </motion.div>

        <div className={clsx(styles.optionsBody, 'dropdown_disableClose')}>
          {children}
        </div>
        <div className={styles.bottomOverlay} />
      </motion.div>
    </div>
  );
};

export default MobileMenuContainer;
