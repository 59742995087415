export const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPod',
    'iPhone',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator?.userAgent.includes('Mac') && 'ontouchend' in document);

export default isIOS;
