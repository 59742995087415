import { Fragment } from 'react';

import clsx from 'clsx';
import type { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

import { getTextDirection } from '../../shared/lib/get-text-direction';
import { Typography } from '../index';

import styles from './slider.module.scss';

type Variants = 'default' | 'spot';

type Size = 'default' | 'small';

export interface ISliderProps<T extends number | string = number>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange' | 'size'
  > {
  className?: string;
  leftHint?: ReactNode | string;
  marks?: { options: number[]; withSnap?: boolean };
  onChange?: (newValue: T) => void;
  rightHint?: ReactNode | string;
  size?: Size;
  snapPoints?: Array<number | string>;
  value?: T;
  variant?: Variants;
}

const centerStabilizers: {
  [key in Size]: { end: number; halfSize: number; start: number };
} = {
  default: {
    end: 0.24,
    halfSize: 12,
    start: 11.6,
  },
  small: {
    end: 0.14,
    halfSize: 7,
    start: 7.1,
  },
};

const variants: { [key in Variants]: { lower: string; upper: string } } = {
  default: {
    lower: '--aurora_special_green',
    upper: '--aurora_neutral_30',
  },
  spot: {
    lower: '--aurora_neutral_80',
    upper: '--aurora_neutral_40',
  },
};

const Slider = <T extends number | string>({
  max = 100,
  min = 0,
  value = 0 as T,
  onChange,
  variant = 'default',
  size = 'default',
  snapPoints,
  className,
  leftHint,
  rightHint,
  marks,
  ...props
}: ISliderProps<T>) => {
  const isRtl = getTextDirection() === 'rtl';

  snapPoints = snapPoints ?? (marks?.withSnap ? marks?.options : undefined);

  if (snapPoints?.length) {
    min = 0;
    max = snapPoints?.length - 1;
    value = snapPoints?.indexOf(value) as T;
  }

  const progress =
    ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100;

  const disabled = props.disabled;
  const leftHintActive = !disabled && progress >= 0;
  const rightHintActive = !disabled && progress === 100;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (snapPoints) {
      onChange?.(snapPoints[e.target.valueAsNumber] as T);

      return;
    }

    onChange?.(e.target.valueAsNumber as T);
  };

  const setSliderMarkActive = (center: number) =>
    !disabled && center <= progress;

  const setInsetInlineStart = (value: number) =>
    `calc(${value}% + ` +
    `(${
      centerStabilizers[size].start -
      value * centerStabilizers[size].end -
      centerStabilizers[size].halfSize
    }px))`;

  const getBackgroundTrack = () =>
    disabled
      ? `var(${variants[variant].upper})`
      : isRtl
        ? `linear-gradient(to left, var(${variants[variant].lower})` +
          ` ${progress}%, var(${variants[variant].upper}) ${progress}%)`
        : `linear-gradient(to right, var(${variants[variant].lower})` +
          ` ${progress}%, var(${variants[variant].upper}) ${progress}%)`;

  const getTypographyVariant = () =>
    size === 'small' ? 'system_h6' : 'system_h4';

  return (
    <div
      className={clsx(styles.wrapper, className, styles[variant], [
        styles[`size-${size}`],
      ])}
    >
      {marks?.options && (
        <div className={styles.marksWrapper}>
          {marks?.options?.map((markProgress, i) => {
            const markValue = marks.withSnap ? i : Number(markProgress);

            const center =
              ((markValue - Number(min)) / (Number(max) - Number(min))) * 100;

            return (
              <Fragment key={markProgress}>
                <span
                  className={clsx(styles.sliderMark, {
                    [styles.sliderMark_disabled]: disabled,
                    [styles.sliderMark_active]: setSliderMarkActive(center),
                  })}
                  style={{
                    insetInlineStart: setInsetInlineStart(center),
                  }}
                  onClick={() => {
                    onChange?.(markProgress as T);
                  }}
                />
                {!disabled && (
                  <div
                    className={styles.tooltip}
                    data-progress={`${Math.round(markProgress)}%`}
                    style={{
                      insetInlineStart: setInsetInlineStart(markProgress),
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      )}

      <div className={styles.sliderInputWrapper}>
        <div
          className={styles.track}
          style={{
            background: getBackgroundTrack(),
          }}
        />
        <input
          {...props}
          max={max}
          min={min}
          type='range'
          value={value}
          onChange={handleChange}
        />
        {!disabled && (
          <div
            className={styles.tooltip}
            data-progress={`${Math.round(progress)}%`}
            style={{
              insetInlineStart: setInsetInlineStart(progress),
            }}
          />
        )}
      </div>
      {(leftHint || rightHint) && (
        <div
          className={clsx(styles.hint, {
            [styles.disabled]: disabled,
          })}
        >
          {typeof leftHint === 'string' ? (
            <Typography
              fontWeight={400}
              variant={getTypographyVariant()}
              className={clsx(styles.hint__title, {
                [styles.hint__title_active]: leftHintActive,
              })}
            >
              {leftHint}
            </Typography>
          ) : (
            leftHint
          )}
          {typeof rightHint === 'string' ? (
            <Typography
              fontWeight={400}
              variant={getTypographyVariant()}
              className={clsx(styles.hint__title, {
                [styles.hint__title_active]: rightHintActive,
              })}
            >
              {rightHint}
            </Typography>
          ) : (
            rightHint
          )}
        </div>
      )}
    </div>
  );
};

export default Slider;
