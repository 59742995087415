import { useCallback, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
} from 'react';

import IconClear from '../../public/icons/icons16/close.svg';
import IconSearch from '../../public/icons/icons24/search.svg';

import styles from './input-search.module.scss';

const variantsClearIcon = {
  close: { opacity: 0, x: 20 },
  //TODO RTL SUPPORT
  open: { opacity: 1, x: 0 },
};

interface IInputSearch
  extends Omit<Partial<InputHTMLAttributes<HTMLInputElement>>, 'size'> {
  placeholder: string;
  searchValue: string;
  setSearchValue: (val: string) => void;
  autoFocus?: boolean;
  className?: string;
  dropdown?: boolean;
  maxLength?: number;
  size?: 'big' | 'medium' | 'small';
  tabIndex?: number;
}

/**
 * InputSearch Component
 *
 * React component for a search input with customizable styles and clear functionality.
 *
 * @component
 * @example
 * // Example usage of InputSearch component within a dropdown
 * <InputSearch
 *   searchValue={searchValue}
 *   setSearchValue={setSearchValue}
 *   placeholder="Search..."
 *   size="medium"
 *   dropdown={true}
 *   autoFocus={true}
 * />
 *
 * @param {string} searchValue - The current value of the search input.
 * @param {function} setSearchValue - Function to set the search input value.
 * @param {string} placeholder - Placeholder text for the search input.
 * @param {'big' | 'medium' | 'small'} [size='medium'] - Size variant for the input.
 * @param {string} [className] - Additional CSS class names to apply to the component.
 * @param {boolean} [dropdown=false] - Boolean indicating whether the input is inside a dropdown.
 * @param {boolean} [autoFocus=false] - Boolean indicating whether the input
 * should be automatically focused when mounted.
 * @param {number} [tabIndex=0] - The tabindex of the input element.
 *
 * @returns {ReactNode} - Rendered InputSearch component.
 */

const InputSearch = ({
  searchValue,
  setSearchValue,
  className,
  size = 'medium',
  dropdown = false,
  autoFocus = false,
  tabIndex = 0,
  maxLength,
  ...props
}: IInputSearch) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const focusInput = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    searchRef.current?.focus();
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      searchRef.current?.focus();
    },
    [setSearchValue]
  );

  const handleClearValue = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (autoFocus && searchRef.current) {
      searchRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div
      role='searchbox'
      tabIndex={tabIndex}
      className={clsx(styles.input__wrapper, className, {
        [styles[size]]: !dropdown,
        [styles.dropdown]: dropdown,
      })}
      onClick={focusInput}
      onKeyDown={focusInput}
    >
      <div className={styles.icon_search}>
        <IconSearch />
      </div>
      <input
        ref={searchRef}
        className={styles.input}
        maxLength={maxLength}
        {...{ role: 'search' }}
        type='search'
        value={searchValue}
        onChange={handleChange}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
        {...props}
      />
      <div className={styles.icon_clear_wrapper}>
        <AnimatePresence mode='wait'>
          {searchValue.length > 0 && (
            <motion.span
              animate='open'
              className={styles.icon_clear}
              exit='close'
              initial='close'
              transition={{ bounce: false, duration: 0.1 }}
              variants={variantsClearIcon}
            >
              <IconClear onClick={handleClearValue} />
            </motion.span>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default InputSearch;
