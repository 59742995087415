'use client';

import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormFieldType } from '../form-factory/lib/form-field-creators/form-field-creators-types';
import isCreateFieldObject from './lib/is-create-field-object';

/**
 * Компонент обертки поля формы для работы с react-hook-form библиотеки.
 * @component
 * В зависимости от переданных пропсов возвращает либо кастомный компонент, либо компонент поля формы, обернутый в Controller.
 *
 * Поля доступные при передаче кастомного компонента:
 * @param {TName} [name] - имя поля, назначенное ему при инициализации формы с помощью react-hook-form библиотеки. В случае с template выступает в роли ключа для корректной работы рендера реакта.
 * @param {ReactNode} [component] - кастомный компонент.
 *
 * Поля доступные при передаче элемента формы:
 * @param {string} [component] - Компонент для отображения в форме. Может быть Input, Checkbox, Textarea.
 * @param {TName} [name] - имя поля, назначенное ему при инициализации формы с помощью react-hook-form библиотеки.
 * @param {Function} [handleOnChange] - функция обертка, принимающая в себя:
 * {
    field: ControllerRenderProps<T>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<T>;
  }.
  Возващает ChangeEventHandler.
 * @param {string} [rules] - правила валидации поля формы типа RegisterOptions<TFieldValues>.
 * @param {FieldProps} [props] - Props того компонента, который передается в поле component.
 * @returns {JSX.Element} Возвращает JSX-элемент поле формы, обернутый в Controller, или кастомный компонент.
 */

const FormField = <TFieldValues extends FieldValues>(
  fieldProps: FormFieldType<TFieldValues>
) => {
  const { control } = useFormContext<TFieldValues>();

  if (isCreateFieldObject(fieldProps)) {
    const Component = fieldProps.component;

    return (
      <Controller
        control={control}
        name={fieldProps.name}
        rules={fieldProps.rules}
        render={({ field, fieldState, formState }) => {
          const handleOnChange = fieldProps.handleOnChange;

          const onChangeHandler = handleOnChange
            ? handleOnChange({ field, fieldState, formState })
            : field.onChange;

          return (
            <Component
              error={fieldState.error?.message}
              name={fieldProps.name}
              value={field.value}
              onChange={onChangeHandler}
              {...fieldProps.props}
            />
          );
        }}
      />
    );
  }

  return <>{fieldProps.component}</>;
};

export default FormField;
