'use client';

import { useState } from 'react';

import clsx from 'clsx';
import type React from 'react';

import { useMediaQuery } from '../../shared/hooks';
import { InputSearch, Typography } from '../index';

import CheckIcon from '../../public/icons/icons24/check.svg';
import SpinIcon from '../../public/icons/icons24/loader-3.svg';

import styles from './selector-search.module.scss';

interface ISelectorSearchTranslations {
  notFoundDefault: string;
  searchDefault: string;
}

interface ISelectorSearchProps<T> {
  items: T[];
  renderItem: (item: T, index?: number) => JSX.Element;
  translations: ISelectorSearchTranslations;
  callbackRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  className?: string;
  classNameItemsList?: string;
  isLoading?: boolean;
  maxHeight?: string;
  noMobileContainer?: boolean;
  notFoundText?: string;
  searchMaxLength?: number;
  searchPlaceHolder?: string;
  searchValue?: string;
  selectedItem?: T;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  width?: number;
}

const SelectorSearch: <T>(
  props: ISelectorSearchProps<T>
) => React.ReactElement<ISelectorSearchProps<T>> = ({
  items,
  renderItem,
  setSearchValue,
  searchPlaceHolder,
  isLoading,
  className,
  maxHeight,
  width,
  classNameItemsList,
  selectedItem,
  searchValue = '',
  notFoundText,
  callbackRef,
  searchMaxLength = 64,
  noMobileContainer = false,
  translations,
}) => {
  const [focusInput, setFocusInput] = useState<boolean>(false);

  const isTabletS = useMediaQuery('tabletS');

  const isSelected = (item: typeof selectedItem) =>
    JSON.stringify(item) === JSON.stringify(selectedItem);

  const getSelectorItems = () => {
    if (isLoading)
      return (
        <div className={styles.selector__loading}>
          <SpinIcon />
        </div>
      );
    else if (!items?.length)
      return (
        <div className={styles.selector__not_found}>
          <Typography as='p' fontWeight={400} variant='system_h4'>
            {notFoundText ?? translations.notFoundDefault}
          </Typography>
        </div>
      );

    return items.map((item, i) => (
      <div key={`item-${i + 1}`} className={styles.item}>
        {renderItem(item, i)}
        {isSelected(item) ? <CheckIcon className={styles.check_icon} /> : null}
      </div>
    ));
  };

  const setFocus = (value: boolean) => {
    if (isTabletS && !noMobileContainer) setFocusInput(value);
  };

  return (
    <div
      className={clsx(styles.selector, className)}
      style={{
        maxHeight: maxHeight ? `${maxHeight}` : '100vh',
        minHeight: focusInput ? '50vh' : 'auto',
        width: width ? `${width}px` : '100%',
      }}
    >
      {setSearchValue && (
        <div className={styles.selector__search}>
          <InputSearch
            maxLength={searchMaxLength}
            placeholder={searchPlaceHolder ?? translations.searchDefault}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
          />
        </div>
      )}
      <div className={clsx(styles.selector__list, classNameItemsList)}>
        {getSelectorItems()}
      </div>
      {callbackRef && (
        <div ref={callbackRef} className={styles.ref__item}></div>
      )}
    </div>
  );
};

export default SelectorSearch;
