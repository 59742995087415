import type { MotionAdvancedProps, MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';

interface MenuAnimationProps {
  children: ReactNode;
  isOpen: boolean;
  variants: MotionProps['variants'];
  exit?: MotionProps['exit'];
  initial?: MotionProps['initial'];
  isMainMenu?: boolean;
  transition?: MotionProps['transition'];
}

/**
 * MenuAnimation Component
 *
 * React component for rendering animated menus using framer-motion.
 *
 * @component
 *
 * @example
 * // Example usage of MenuAnimation component
 * <MenuAnimation
 *   isOpen={isMenuOpen}
 *   variants={menuVariants}
 *   initial='visible'
 *   exit={{ x: '100%' }}
 *   transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
 * >
 *   {menuContent}
 * </MenuAnimation>
 *
 * @param {Object} props - The properties of the MenuAnimation component.
 * @param {boolean} props.isOpen - Flag indicating whether the menu is open.
 * @param {MotionProps['variants']} props.variants - Variants for animation states.
 * @param {MotionProps['initial']} [props.initial='hidden'] - Initial animation state.
 * @param {MotionProps['exit']} props.exit - Animation state when the menu is exiting.
 * @param {MotionProps['transition']} props.transition - Animation transition configuration.
 * @param {ReactNode} props.children - The content to be displayed within the animated menu.
 *
 * @returns {ReactNode} - Rendered MenuAnimation component.
 */
const MenuAnimation: FC<MenuAnimationProps & MotionAdvancedProps> = ({
  isOpen,
  variants,
  initial = 'hidden',
  exit,
  transition,
  children,
  isMainMenu,
  custom,
}) => (
  <AnimatePresence>
    <motion.div
      animate={isOpen ? 'visible' : 'hidden'}
      custom={custom}
      exit={exit}
      initial={initial}
      style={{ position: !isOpen && isMainMenu ? 'absolute' : 'relative' }}
      transition={transition}
      variants={variants}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

export default MenuAnimation;
