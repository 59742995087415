'use client';

import { useRef } from 'react';

import type { Variants } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type { ChangeEvent, InputHTMLAttributes } from 'react';

import styles from './switch.module.scss';

interface ISwitchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> {
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
}

const variants = {
  off: {
    insetInlineStart: '2px',
    width: [null, '25px', '20px'],
  },
  on: {
    insetInlineStart: '18px',
    width: [null, '25px', '20.01px'],
  },
};

const transition = {
  duration: 0.3,
  ease: [0.4, 0, 0.6, 1],
  times: [0, 0.4, 1],
};

/**
 * Switch component
 *
 * @component
 * @example
 * // Example usage of Switch component
 * <Switch onChange={handleChange} checked={isChecked} disabled={isDisabled} />
 *
 * @returns {JSX.Element} Rendered Switch component.
 */

const Switch = ({ onChange, checked, ...props }: ISwitchProps) => {
  const thumbRef = useRef<HTMLDivElement>(null);

  return (
    <label className={styles['toggle-switch']}>
      <input
        {...props}
        aria-checked={checked}
        checked={checked}
        role='switch'
        type='checkbox'
        onChange={(e) => {
          onChange?.(e.target.checked, e);
        }}
      />
      <div className={styles.switch}>
        <AnimatePresence mode='wait'>
          <motion.div
            ref={thumbRef}
            animate={checked ? 'on' : 'off'}
            className={styles.switchThumb}
            initial={checked ? 'on' : 'off'}
            transition={transition}
            variants={variants as Variants}
          />
        </AnimatePresence>
      </div>
    </label>
  );
};

export default Switch;
