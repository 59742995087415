import clsx from 'clsx';
import type { ReactNode } from 'react';

import Typography from '../../typography';
import type { Variants } from '../input.types';
import { variantsTypography } from '../input.types';

import styles from './input-label.module.scss';

interface IInputLabel {
  inputId?: string;
  isRightLabel?: boolean;
  label?: string | ReactNode;
  variant?: Variants;
}

export const InputLabel = ({
  label,
  variant = 'normal',
  inputId,
  isRightLabel,
}: IInputLabel) => (
  <label
    htmlFor={inputId}
    className={clsx(styles.label, {
      [styles.right]: isRightLabel,
      [styles.left]: !isRightLabel,
    })}
  >
    {typeof label === 'string' ? (
      <Typography
        fontWeight={variantsTypography[variant].label.fontWeight}
        variant={variantsTypography[variant].label.variant}
      >
        {label}
      </Typography>
    ) : (
      label
    )}
  </label>
);
