import { Fragment } from 'react';

import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './infinite-scroll.module.scss';

interface IInfiniteScrollProps<T> {
  items: T[];
  renderItem: (item: T) => ReactNode;
  variant: 'normal' | 'compact';
  className?: string;
}

export const InfiniteScroll = <T,>({
  items,
  renderItem,
  className,
  variant,
}: IInfiniteScrollProps<T>) => (
  <div className={clsx(styles.scroll_container, className)}>
    <div
      className={clsx(styles.scroll_parent, {
        [styles.scroll_parent_compact]: variant === 'compact',
        [styles.scroll_parent_normal]: variant === 'normal',
      })}
    >
      <div className={styles.scroll_element}>
        {items.map((item, i) => (
          <Fragment key={`infinite-scroll-item-${i + 1}`}>
            {renderItem(item)}
          </Fragment>
        ))}
      </div>
      <div className={styles.scroll_element}>
        {items.map((item, i) => (
          <Fragment key={`infinite-scroll-item-${i + 1}`}>
            {renderItem(item)}
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

export default InfiniteScroll;
