import type { ComponentProps, ComponentType } from 'react';
import type { FieldValue, FieldValues } from 'react-hook-form';

import Checkbox from '../../checkbox/checkbox';
import type {
  FormFieldComponentType,
  FormFieldType,
  ICreateFieldObject,
} from '../../form-factory/lib/form-field-creators/form-field-creators-types';
import Input from '../../input/input';
import Textarea from '../../textarea/textarea';

const isCreateFieldObject = <
  TName extends FieldValue<TFieldValues>,
  TFieldValues extends FieldValues,
  FieldProps = ComponentProps<ComponentType>,
>(
  field: FormFieldType<TFieldValues>
): field is ICreateFieldObject<
  TName,
  HTMLElement,
  FormFieldComponentType,
  TFieldValues,
  FieldProps
> =>
  field.component === Input ||
  field.component === Checkbox ||
  field.component === Textarea;

export default isCreateFieldObject;
