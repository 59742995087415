'use client';

import clsx from 'clsx';
import type { FC } from 'react';

import { Typography } from '../../index';

import ChevronIcon from '../../../public/icons/icons20/chevron-right.svg';

import styles from './table-pagination.module.scss';

interface ITablePaginationTranslations {
  paginationText: string;
}

interface ITablePaginationProps {
  currentPage: number;
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  translations: ITablePaginationTranslations;
  count?: number;
}

const TablePagination: FC<ITablePaginationProps> = ({
  count,
  currentPage,
  fetchNextPage,
  fetchPreviousPage,
  translations,
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === count;

  return (
    <div className={styles.pagination}>
      {count ? (
        <Typography
          className={styles.pagination__info}
          fontWeight={400}
          variant='system_h5'
        >
          <span>{currentPage}</span> {translations.paginationText}{' '}
          <span>{count}</span>
        </Typography>
      ) : null}

      <button
        disabled={isFirst}
        className={clsx(
          styles.pagination__arrow,
          styles.pagination__arrow_reverse,
          {
            [styles.pagination__arrow_disabled]: isFirst,
          }
        )}
        onClick={fetchPreviousPage}
      >
        <ChevronIcon />
      </button>
      {count ? null : (
        <Typography
          className={clsx(styles.pagination__info, styles.no_count)}
          fontWeight={400}
          variant='system_h5'
        >
          <span>{currentPage}</span>
        </Typography>
      )}
      <button
        disabled={isLast}
        className={clsx(styles.pagination__arrow, {
          [styles.pagination__arrow_disabled]: isLast,
        })}
        onClick={fetchNextPage}
      >
        <ChevronIcon />
      </button>
    </div>
  );
};

export default TablePagination;
