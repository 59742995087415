'use client';

import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import type { Placement } from '@floating-ui/react';
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  safePolygon,
  shift,
  useClick,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';
import type React from 'react';
import type { ReactNode } from 'react';

import { useMediaQuery } from '../../shared/hooks';
import MenuContainer from '../menu-container/menu-container';

import styles from './dropdown-menu.module.scss';

interface IDropdownMenuProps {
  children: React.ReactNode;
  isOpen: boolean;
  parent: (isOpen?: boolean) => ReactNode;
  setIsOpen: (value: boolean) => void;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  inModal?: boolean;
  isOpenOnHover?: boolean;
  maxHeight?: number;
  menuContainerClassName?: string;
  mobileBottomSheet?: boolean;
  mobileMenuContentClassName?: string;
  mobileTitle?: string;
  noMobileContainer?: boolean;
  offset?: number;
  onCloseEvent?: () => void;
  onlyMobileContainer?: boolean;
  parentClassName?: string;
  parentWrapperClassName?: string;
  placement?: Placement;
  width?: number;
}

/**
 * DropdownMenu Component
 *
 * React component for rendering a customizable dropdown menu with title and content.
 *
 * @component
 *
 * @example
 * // Example usage of DropdownMenu component
 * <DropdownMenu
 *   parent={(isOpen?: boolean) => <Button>{isOpen ? 'Close Menu' : 'Open Menu'}</Button>}
 *   isOpen={true}
 *   setIsOpen={setIsOpenFunction}
 * >
 *   <MenuItem onClick={() => console.log("Option 1")}>Option 1</MenuItem>
 *   <MenuItem onClick={() => console.log("Option 2")}>Option 2</MenuItem>
 *   <MenuItem onClick={() => console.log("Option 3")}>Option 3</MenuItem>
 * </DropdownMenu>
 *
 * @param {Object} props - The properties of the DropdownMenu component.
 * @param {Function} props.parent - Function returning the title to be displayed
 * on the dropdown button.
 * @param {ReactNode} props.children - The content to be displayed within the dropdown menu.
 * @param {Function} [props.onCloseEvent] - Callback function to be executed
 * when the dropdown is closed.
 * @param {boolean} [props.isOpenOnHover] - Whether the dropdown should be open on hover.
 * @param {number} [props.maxHeight] - Maximum height of the dropdown menu.
 * @param {number} [props.offset] - Custom offset of the dropdown menu.
 * @param {number} [props.width] - Width of the dropdown menu.
 * @param {boolean} props.isOpen - Whether the dropdown menu is open.
 * @param {function} props.setIsOpen - Function to set the state of the dropdown menu.
 * @param {string} props.className - ClassName value for dropdown menu wrapper

 *
 * @returns {ReactNode} - Rendered DropdownMenu component.
 */

const DropdownMenu = ({
  parent,
  children,
  onCloseEvent,
  isOpenOnHover,
  maxHeight,
  width,
  isOpen,
  setIsOpen,
  className,
  offset: customOffset,
  mobileTitle,
  containerClassName,
  parentClassName,
  parentWrapperClassName,
  menuContainerClassName,
  placement = 'bottom-start',
  mobileBottomSheet = true,
  disabled = false,
  noMobileContainer = false,
  inModal = false,
  onlyMobileContainer = false,
  mobileMenuContentClassName,
}: IDropdownMenuProps) => {
  const isLaptop = useMediaQuery('laptop');
  const isTabletS = useMediaQuery('tabletS');

  const [mounted, setMounted] = useState<boolean>(false);

  const isMobileContainer = useMemo(
    () =>
      (!noMobileContainer && isTabletS && mobileBottomSheet) ||
      onlyMobileContainer,
    [isTabletS, mobileBottomSheet, noMobileContainer]
  );

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(customOffset || (isLaptop ? 16 : 22)), shift()],
    onOpenChange: setIsOpen,
    open: !disabled && isOpen,
    placement: placement,
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });
  const headingId = useId();
  const focus = useFocus(context);
  const { delay } = useDelayGroupContext();

  const hover = useHover(context, {
    delay,
    enabled: !!isOpenOnHover,
    handleClose: safePolygon({
      buffer: 1.5,
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
    hover,
    focus,
  ]);

  useEffect(() => {
    if (!isOpen && onCloseEvent) {
      onCloseEvent();
    }
  }, [isOpen]);

  const keepFocus = (e: FocusEvent) => {
    if (refs.floating.current && e.relatedTarget === null) {
      refs.floating.current.focus();
    }
  };
  // TODO
  // useEffect(() => {
  //   if (!refs.floating.current) {
  //     return;
  //   }

  //   refs.floating.current.addEventListener('focusout', keepFocus);

  //   return () => {
  //     if (refs.floating.current) {
  //       refs.floating.current.removeEventListener('focusout', keepFocus);
  //     }
  //   };
  // }, [refs.floating.current]);

  useEffect(
    () => refs?.floating?.current?.removeEventListener('focusout', keepFocus),
    []
  );

  useEffect(() => {
    if (!isMobileContainer) {
      const handleResize = () => setIsOpen(false);

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isMobileContainer]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleOnClose = () => {
    setIsOpen(false);
    onCloseEvent?.();
  };

  const floatingEl = (
    <FloatingFocusManager
      context={context}
      modal={isMobileContainer}
      order={['floating', 'content']}
    >
      <div
        ref={refs.setFloating}
        aria-labelledby={headingId}
        style={{ ...floatingStyles, zIndex: 999 }}
        {...getFloatingProps()}
        className={containerClassName}
      >
        <MenuContainer
          isMobileContainer={isMobileContainer}
          isOpen={isOpen}
          maxHeight={maxHeight}
          menuContainerClassName={menuContainerClassName}
          mobileMenuContentClassName={mobileMenuContentClassName}
          mobileTitle={mobileTitle}
          placement={placement}
          width={width}
          onCloseEvent={handleOnClose}
        >
          <div
            className={clsx(styles.menuWrapper, className, {
              [styles.no_mobile]: noMobileContainer,
            })}
          >
            {children}
          </div>
        </MenuContainer>
      </div>
    </FloatingFocusManager>
  );

  return (
    <div className={clsx(styles.dropdown, parentWrapperClassName)}>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={parentClassName}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {parent(isOpen)}
      </div>

      {inModal
        ? floatingEl
        : mounted && createPortal(floatingEl, document.body)}
    </div>
  );
};

export default DropdownMenu;
