'use client';

import { type ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { useMediaQuery } from '../../../shared/hooks';
import Typography from '../../typography/typography';

import IconArrowRight from '../../../public/icons/icons24/chevron-right.svg';

import styles from './table-header.module.scss';

interface ITableHeaderTranslations {
  showMore: string;
}

export interface ITableHeader {
  title: ReactNode | string;
  translations: ITableHeaderTranslations;
  className?: string;
  handleClickMore?: () => void;
  hideArrowNext?: boolean;
  hideShowMore?: boolean;
  isTransactionsEnabled?: boolean;
}

export const TableHeader = ({
  title,
  handleClickMore,
  hideShowMore = false,
  hideArrowNext,
  className,
  isTransactionsEnabled = false,
  translations,
}: ITableHeader) => {
  const isTablet = useMediaQuery('tablet');

  const getTitle = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <Typography
          as='p'
          className={clsx(styles.tableTitle, styles.isText)}
          fontWeight={500}
          variant='system_h4'
        >
          {title}
        </Typography>
      );
    }

    return title;
  }, [title]);

  return (
    <div className={clsx(styles.tableHeader, className)}>
      {!isTablet && getTitle}

      <button className={styles.button}>
        {isTablet && getTitle}
        <Typography as='span' className={styles.buttonWrapper} variant='button'>
          {!hideShowMore && (
            <span
              className={clsx(styles.buttonText, {
                [styles.buttonText_transactionsEnabled]: isTransactionsEnabled,
              })}
              onClick={handleClickMore && handleClickMore}
            >
              {translations.showMore}
            </span>
          )}
          {!hideArrowNext && (
            <i
              className={styles.buttonIcon}
              onClick={handleClickMore && handleClickMore}
            >
              <IconArrowRight />
            </i>
          )}
        </Typography>
      </button>
    </div>
  );
};

export default TableHeader;
