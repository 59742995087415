import clsx from 'clsx';

import Typography from '../typography';

import styles from './counter-badge.module.scss';

export interface ICounterBadgeProps {
  counter: number;
  className?: string;
  size?: 'medium' | 'small';
}

export const CounterBadge = ({
  counter,
  className,
  size = 'medium',
}: ICounterBadgeProps) => (
  <Typography
    as='span'
    className={clsx(styles.counter, className, styles[size])}
    variant='tag'
  >
    {counter > 99 ? 99 : counter}
  </Typography>
);

export default CounterBadge;
