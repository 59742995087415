'use client';

import { useState } from 'react';

import clsx from 'clsx';
import type { FC } from 'react';

import { DropdownMenu, IconButton, Typography } from '../../index';

import ChevronIcon from '../../../public/icons/icons16/chevron-down.svg';

import styles from './table-dropdown.module.scss';

interface ITableDropdownTranslations {
  itemsText: string;
}

interface ITableDropdown {
  counts: number[];
  handleChangeItemsPerPage: (perPage: number) => void;
  itemsPerPage: number;
  translations: ITableDropdownTranslations;
  isLoading?: boolean;
}

const TableDropdown: FC<ITableDropdown> = ({
  itemsPerPage,
  handleChangeItemsPerPage,
  counts,
  translations,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <DropdownMenu
      noMobileContainer
      className={styles.dropdown}
      isOpen={isOpen}
      menuContainerClassName={styles.dropdownMenuContainer}
      offset={counts.length}
      placement='top-end'
      setIsOpen={setIsOpen}
      width={59}
      parent={() => (
        <div
          className={clsx(styles.container, {
            [styles.container_opened]: isOpen,
          })}
        >
          <Typography fontWeight={400} variant='system_h5'>
            {translations.itemsText}
          </Typography>
          <Typography fontWeight={400} variant='system_h5'>
            {itemsPerPage}
          </Typography>
          <IconButton
            appearance='stroked'
            className={clsx(styles.icon, { [styles.icon_opened]: isOpen })}
          >
            <ChevronIcon />
          </IconButton>
        </div>
      )}
      onCloseEvent={handleCloseModal}
    >
      <ul className={styles.list}>
        {counts.map((item) => (
          <li
            key={item}
            className={clsx(styles.list__item, {
              [styles.list__item_active]: item === itemsPerPage,
            })}
            onClick={() => {
              handleChangeItemsPerPage(item);
              setIsOpen(!isOpen);
            }}
          >
            <Typography fontWeight={400} variant='system_h4'>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </DropdownMenu>
  );
};

export default TableDropdown;
