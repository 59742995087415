'use client';

import type { Placement } from '@floating-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';

import { MobileMenuContainer } from '../index';

import styles from './menu-container.module.scss';

const variants = {
  bottom: {
    closed: {
      opacity: 0,
      y: '75px',
    },
    open: {
      opacity: 1,
      y: 0,
    },
  },
  left: {
    closed: {
      opacity: 0,
      x: '-75px',
    },
    open: {
      opacity: 1,
      x: 0,
    },
  },
  right: {
    closed: {
      opacity: 0,
      x: '75px',
    },
    open: {
      opacity: 1,
      x: 0,
    },
  },
  top: {
    closed: {
      opacity: 0,
      y: '-75px',
    },
    open: {
      opacity: 1,
      y: 0,
    },
  },
} as const;

const menuTransition = {
  opacity: { duration: 0.1 },
  y: { damping: 40, stiffness: 500, type: 'spring' },
};

interface IMenuContainerProps {
  children: ReactNode;
  isOpen: boolean;
  placement: Placement;
  isMobileContainer?: boolean;
  maxHeight?: number;
  menuContainerClassName?: string;
  mobileMenuContentClassName?: string;
  mobileTitle?: string;
  onCloseEvent?: () => void;
  width?: number;
}

const MenuContainer: FC<IMenuContainerProps> = ({
  children,
  isOpen,
  maxHeight,
  width,
  placement,
  onCloseEvent,
  mobileTitle,
  isMobileContainer = false,
  menuContainerClassName,
  mobileMenuContentClassName,
}) => {
  if (isMobileContainer) {
    return (
      <AnimatePresence>
        {isOpen && (
          <MobileMenuContainer
            contentClassName={mobileMenuContentClassName}
            mobileTitle={mobileTitle}
            onCloseEvent={() => onCloseEvent?.()}
          >
            {children}
          </MobileMenuContainer>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          animate='open'
          className={clsx(styles.container, menuContainerClassName)}
          exit='closed'
          initial='closed'
          transition={menuTransition}
          variants={variants[placement.split('-')[0] as keyof typeof variants]}
          style={{
            maxHeight: maxHeight ? `${maxHeight}px` : 'max-content',
            width: width ? `${width}px` : 'max-content',
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MenuContainer;
