import Link from 'next/link';

import clsx from 'clsx';
import type React from 'react';
import type { ReactNode } from 'react';

import { getLocaleLink } from '../../../../shared/lib';
import type { ILink } from '../../../../shared/types/link';
import type { Locale } from '../../../../shared/types/navigation';
import Typography from '../../../typography';

import styles from './tab-dropdown-item.module.scss';

export interface ITabDropdownProps {
  title: string;
  description?: string;
  hasChildren?: boolean;
  icon?: ReactNode;
  link?: ILink;
  locale?: Locale;
  onClick?: () => void;
}

const TabDropdownItem: React.FC<ITabDropdownProps> = ({
  title,
  description,
  icon,
  link,
  onClick,
  hasChildren = false,
  locale = 'en',
}) => {
  const getItem = () => (
    <>
      {icon && <span className={styles.item__icon}>{icon}</span>}
      <div className={styles.item__content}>
        <Typography as='p' fontWeight={500} variant='system_h4'>
          {title}
        </Typography>
        {description && (
          <Typography as='span' fontWeight={400} variant='system_h5'>
            {description}
          </Typography>
        )}
      </div>
    </>
  );

  if (!hasChildren && link) {
    const getLink =
      link.type === 'internal'
        ? link.href
        : getLocaleLink(link.href, locale as string);

    return (
      <Link
        className={styles.item}
        href={getLink}
        tabIndex={0}
        onClick={() => onClick?.()}
      >
        {getItem()}
      </Link>
    );
  }

  return (
    <button
      className={clsx(styles.item, { [styles.with_children]: hasChildren })}
      tabIndex={0}
      onClick={() => {
        !hasChildren && onClick?.();
      }}
    >
      {getItem()}
    </button>
  );
};

export default TabDropdownItem;
