import type { FieldValue, FieldValues } from 'react-hook-form';

import Checkbox from '../../../checkbox/checkbox';
import Input from '../../../input/input';
import Textarea from '../../../textarea/textarea';
import type {
  ICreateField,
  ICreateFieldObject,
  ICreateTemplate,
} from './form-field-creators-types';

export const createInputField = <
  TName extends keyof TFieldValues,
  TFieldValues extends FieldValues = FieldValues,
  FieldProps = unknown,
>(
  createFieldProps: ICreateField<
    TName,
    HTMLInputElement,
    TFieldValues,
    FieldProps & React.ComponentProps<typeof Input>
  >
): ICreateFieldObject<
  TName,
  HTMLInputElement,
  typeof Input,
  TFieldValues,
  FieldProps & React.ComponentProps<typeof Input>
> => ({
  ...createFieldProps,
  component: Input,
});

export const createCheckboxField = <
  TName extends FieldValue<TFieldValues>,
  TFieldValues extends FieldValues,
  FieldProps = unknown,
>(
  createFieldProps: ICreateField<
    TName,
    HTMLInputElement,
    TFieldValues,
    FieldProps & React.ComponentProps<typeof Checkbox>
  >
): ICreateFieldObject<
  TName,
  HTMLInputElement,
  typeof Checkbox,
  TFieldValues,
  FieldProps & React.ComponentProps<typeof Checkbox>
> => ({
  ...createFieldProps,
  component: Checkbox,
});

export const createTextareaField = <
  TName extends FieldValue<TFieldValues>,
  TFieldValues extends FieldValues,
  FieldProps = unknown,
>(
  createFieldProps: ICreateField<
    TName,
    HTMLTextAreaElement,
    TFieldValues,
    FieldProps & React.ComponentProps<typeof Textarea>
  >
): ICreateFieldObject<
  TName,
  HTMLTextAreaElement,
  typeof Textarea,
  TFieldValues,
  FieldProps & React.ComponentProps<typeof Textarea>
> => ({
  ...createFieldProps,
  component: Textarea,
});

export const createTemplateField = <
  TName extends FieldValue<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  name,
  component,
}: ICreateTemplate<TName, TFieldValues>): ICreateTemplate<
  TName,
  TFieldValues
> => ({
  component,
  name,
});
