import ErrorIcon from '../../public/icons/icons16/alert-circle.svg';

import styles from './error-input.module.scss';
interface IErrorInputProps {
  children: string;
}

export const ErrorInput = ({ children }: IErrorInputProps) => (
  <div className={styles.input__error}>
    <ErrorIcon className={styles.error__icon} />
    <span className={styles.error__description}>{children}</span>
  </div>
);

export default ErrorInput;
