import { useEffect, useState } from 'react';

import type React from 'react';

import IconBackspace from '../../public/icons/icons24/icon-backspace.svg';

import styles from './pin-input-mobile.module.scss';

const mobilePinDigits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

interface IPinInputMobile {
  isLoading: boolean;
  pinDigits: string[];
  setPinDigits: React.Dispatch<React.SetStateAction<string[]>>;
}

const PinInputMobile = ({
  pinDigits,
  setPinDigits,
  isLoading,
}: IPinInputMobile) => {
  const [indexDigit, setIndexDigit] = useState<number>(0);

  const length = pinDigits.length;

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    digit: string
  ) => {
    e.preventDefault();

    if (indexDigit > length - 1) {
      return;
    }

    const oldDigit = pinDigits[indexDigit];
    // старую цифру из поля ввода убираем, оставляя только новую
    const newDigit = digit.trim().replace(oldDigit, '');
    // теперь вызываем callback родителя, чтобы обовить digits
    const newDigits = [...pinDigits]; // копия digits

    newDigits[indexDigit] = newDigit;
    setPinDigits(newDigits);
    setIndexDigit(indexDigit + 1);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!pinDigits[indexDigit - 1]) return;

    const oldDigit = pinDigits[indexDigit - 1];
    const newDigit = oldDigit.replace(oldDigit, '');
    const newDigits = [...pinDigits];

    newDigits[indexDigit - 1] = newDigit;
    setPinDigits(newDigits);
    setIndexDigit(indexDigit - 1);
  };

  useEffect(() => {
    pinDigits[0] === '' && setIndexDigit(0);
  }, [pinDigits[0]]);

  return (
    <div className={styles.pin__wrapper}>
      {mobilePinDigits.map((digit) => (
        <button
          key={digit}
          disabled={isLoading}
          onClick={(e) => handleClick(e, digit)}
        >
          {digit}
        </button>
      ))}
      <button disabled={isLoading} onClick={(e) => handleDelete(e)}>
        <IconBackspace />
      </button>
    </div>
  );
};

export default PinInputMobile;
